import React from 'react';
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next';

import Button from 'components/Button'

import './Popup.scss';

function PopupSuccess ({ opened, onClose, onConfirm }) {
  const { t } = useTranslation();
  return (
    <Popup
      open={opened}
      onClose={onClose}
    >
      <div className="popup popup-consent">
        <h1>{t("label.datashare.popup.reject.success.h1")}</h1>

        <div className="popup-row popup-row-buttons">
          <Button
            type="Primary"
            label="OK"
            onClick={onConfirm}
          />
        </div>

      </div>
  </Popup>
  )
}

export default PopupSuccess;