import React from 'react'

import './style.sass'

const Label = React.forwardRef(({ label, relation, mandatory }, ref) => (
  <label ref={ref} htmlFor={relation} className={`dsar-label custom ${mandatory? 'dsar-mandatory': ''}`}>
    {label}
  </label>
))

export default Label
