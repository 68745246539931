import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Loading } from 'components/Icons'

import './styles.sass'

function Logo() {

	useEffect(() => {
		setTimeout(() => {
			setShowLogo(true);
		}, 1000)
	}, [])

	const { geral = {} } = useSelector((store) => store)
	const [showLogo, setShowLogo] = useState(false);
	const logo = geral.portalConfig.logoURL

	return (
		<Link className="pvt-logo custom" to={"/"} title='Privacy tools'>
			{
				showLogo ?
					<img alt="Logo" src={logo} />
					: <Loading />
			}
		</Link>
	)
}

export default Logo