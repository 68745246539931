export const registrationDataLabel = {
    'personData': [
        'name',
        'birthDate',
        'homeAddress',
        'maritalStatus',
        'cellNo',
        'landlineNumber',
        'email',
        'nationality',
    ],

    'documents': [
        'CPF',
        'RG'
    ],

    'familyData': [
        'natureOfAffiliation',
        'membershipData',
        'spouseInformation'
    ],

    'employmentData': [
        'occupationReference',
        'incomeValue',
        'frequencyOfReceiptOfIncome',
        'incomeInformedDate',
        'profession',
        'employerInformation'
    ],

    'equityData': [
        'informedEquityValue',
        'startDateOfRelationshipWithBank',
        'listOfCurrentContracts'
    ],
}

export const transactionalDataLabel = {
    'products': [
        'productName',
        'listOfContractedModalities',
    ],

    'contracts': [
        'noOfContracts',
        'balance',
        'deadline',
        'value',
        'fees',
        'rates',
    ],

    'datas': [
        'finalExpirationDate',
        'contractData',
        'paymentData',
        'parcelsExpirationDate',
    ],

    'features': [
        'amountOfBenefitsToBeDue',
        'amountOfBenefitsYouPay',
        'amountOfOverdue',
        'paymentValueInTheLast12Months',
    ],
}

export const investmentDataLabel = {
    'Bank_Fixed_Income': [
        'Product_Identification',
        'Product_identification_information',
        'Product_compensation_information',
        'Due_date',
        'Date_of_acquisition',
        'End_date',
        'Shortage',
    ],
    'Customer_position': [
        'Balance_blocked',
        'Net_and_gross_balance',
        'Title_quantity',
        'Unit_price',
        'Provisioned_taxes',
    ],
    'Movements': [
        'Movement_identification',
        'Unit_prices_and_quantity_moved',
        'Net_and_gross_values_of_movement',
        'Taxes_and_indexers',
    ],
}