import axios from 'axios'

import handleFetchError from './handleFetchError'

/*export const endPoints = {
  api: {
    base: 'https://demo.privacytools.com.br/public_api/',
    site:
      'portal/KeaglRUvmATfAvmO__bar__7Ws1wiKv__plus__nPCPsG5yDZGZBrWBRB__plus__OgZy911JbDvWPuSYf9ynxCCPGwubuyT3oK5RSn__plus__CA__equal____equal__',
    lang: 'pt_BR',
    signup: 'auth/register.html',
    signupPincode: 'create/pincode.html',
    savePassword: 'auth/savePassword.html',
    singin: 'auth/login.html',
    signInPincode: 'auth/pincode.html',
    preferences: 'consents/myConsents.html',
    updatePreferences: 'consents/saveConsents.html'
  },
}*/

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
})

export function interceptors() {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => handleFetchError(error),
  )
}
