import produce from "immer";
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

const TYPES = {
    SHOW_MENU: 'menu/SHOW',
    HIDE_MENU: 'menu/HIDE',
    TOGGLE: 'menu/TOGGLE'
};

const initialState = {
    visible: false
};

const menuReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case TYPES.SHOW_MENU:
                draft.visible = true;
                break;
            case TYPES.HIDE_MENU:
                draft.visible = false;
                break;
            case TYPES.TOGGLE:
                draft.visible = !draft.visible;
                break;
        }

        return draft;
    });
};

export { TYPES };

export default menuReducer;
