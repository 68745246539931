import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import YourRequestDetail from '../YourRequestDetail';

import FormPinCode from './FormPinCode';
import FormProtocol from './FormProtocol';

function GetRequetsByProtocol() {
  const { geral: { language } = {} } = useSelector(state => state)
  const [formProtocolData, setFormProtocolData] = useState();
  const [tokenByProtocol, setTokenByProtocol] = useState();

  if (tokenByProtocol && formProtocolData?.protocol) {
    return (
      <YourRequestDetail
        tokenByProtocol={tokenByProtocol}
        onClickBackButton={() => {
          setTokenByProtocol(undefined);
          setFormProtocolData(undefined);
        }}
        protocol={formProtocolData.protocol}
      />
    )
  }
  
  if (formProtocolData) {
    return (
      <FormPinCode
        setTokenByProtocol={setTokenByProtocol}
        formProtocolData={formProtocolData}
      />
    )
  }

  return (
    <FormProtocol
      setFormProtocolData={setFormProtocolData}
    />
  )
}

export default React.memo(GetRequetsByProtocol)