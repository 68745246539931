
import { isEmail } from 'validator'
import { cpf } from "cpf-cnpj-validator";

const mandatoryValidation = (value) => value && !!value.trim().length

export const validationHelper = {
    name: {
        mandatory: true,
        mandatoryValidation: mandatoryValidation,
    },
    document: {
        mandatory: true,
        mandatoryValidation: mandatoryValidation,
        validation: (doc) => (!!cpf.isValid(doc) && !doc.includes('.') && !doc.includes('-')),
    },
    email: {
        mandatory: true,
        mandatoryValidation: mandatoryValidation,
        validation: (email) => !!isEmail(email),
    },
    confirmEmail: {
        mandatory: true,
        mandatoryValidation: mandatoryValidation,
    },
    phoneNumber: {
        mandatory: false,
        validation: (phone) => {
            const onlyNumbers = phone.replace(/[^\d]/g, "");
            return onlyNumbers.trim().length === 0 || onlyNumbers.trim().length >= 10
            //return !!isMobilePhone(phone);
        },
    },
    country: {
        mandatory: false,
        mandatoryValidation: mandatoryValidation,
    }
}

export const ErrorTypes = {
    MANDATORY: 'mandatory',
    INVALID: 'invalid',
}

export const ErrorMessages = {
    name: {
        mandatory: 'signup.validation.error.name'
    },
    document: {
        mandatory: 'signup.validation.error.document',
        invalid: 'signup.validation.error.documentInvalid'
    },
    email: {
        mandatory: 'signup.validation.error.email',
        invalid: 'signup.validation.error.emailInvalid'
    },
    confirmEmail: {
        mandatory: 'signup.validation.error.confirmEmail',
        invalid: 'signup.validation.error.confirmEmailInvalid'
    },
    phoneNumber: {
        invalid: 'signup.validation.error.phoneNumber'
    },
    country: {
        mandatory: 'signup.validation.error.country'
    }
}
export const initialState = {
    name: '',
    document: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    country: '',
    errors: {
        name: {
            error: false,
            type: '',
        },
        document: {
            error: false,
            type: '',
        },
        email: {
            error: false,
            type: '',
        },
        confirmEmail: {
            error: false,
            type: '',
        },
        phoneNumber: {
            error: false,
            type: '',
        },
        country: {
            error: false,
            type: '',
        }
    },
}

export const Types = {
    DATA: 'create-account/data',
    ERROR: 'create-account/ERROR',
    VALID: 'create-account/VALID'
}

function reducer(state, { type, payload }) {
    switch (type) {
        case Types.DATA:
            const { key, ...rest } = payload
            return {
                ...state,
                ...rest,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: false
                    },
                },
            }
        case Types.ERROR: {
            const { key, errorType } = payload
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: true,
                        type: errorType,
                    },
                },
            }
        }

        case Types.VALID: {
            const { key } = payload
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: false,
                        type: '',
                    },
                },
            }
        }

        default: {
            return state
        }
    }
}

export default reducer