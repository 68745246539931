import * as React from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { useTranslation } from 'react-i18next'


import Auth from 'components/Auth'

import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import { Loading } from 'components/Icons'

// reducer
import reducer, { Types, initialState, validationHelper, ErrorMessages, ErrorTypes, } from './reducer'
import { useDispatch, useSelector } from 'react-redux'
import { signUp as sendSignUp } from 'store/reducers/auth.thunks'
import { getCountries } from 'store/reducers/geral.thunks'
// router
import { Redirect } from 'react-router-dom'

function SignUp() {
  const { t, i18n } = useTranslation()
  const reduxDispatch = useDispatch()
  const captchakey = process.env.REACT_APP_RECAPTCHA_KEY
  const emptyOption = { label: '', value: '' }
  const { auth, geral = {} } = useSelector(state => state)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [captchaValue, setCaptchaValue] = React.useState('')
  const [country, setCountry] = React.useState(emptyOption)
  const [countries, setCountries] = React.useState(emptyOption)
  const [systemLang, setSystemLang] = React.useState({ lang: i18n.language === 'pt' ? 'pt-BR' : i18n.language })
  const useDocument = geral.portalConfig.portalAuthType === 'AUTH_REGISTRATION_EMAIL_DOCUMENT'

  React.useEffect(() => {
    reduxDispatch(getCountries())
    i18n.on('languageChanged', function (lng) {
      setSystemLang({ lang: lng === 'pt' ? 'pt-BR' : lng })
    })
  }, [systemLang])

  React.useEffect(() => {
    if (geral.countries !== null && geral.countries.length > 0) {
      const countries = geral.countries
        .map((c) => ({
          label: geral.language === "en" ?
            c.nameEn
            :
            (geral.language === "es" ? c.nameEs : c.namePt),
          value: c.code,
        }))
      setCountries(countries)
    }
  }, [geral.countries])

  function submitForm() {
    const { errors, ...data } = state
    let hasErrors = false
    Object.entries(data).forEach(([key, val]) => {
      const mustValidateDocument = key === 'document' && state.country === 'BR'
      if (mustValidateDocument || ((key !== 'document' || useDocument) && validationHelper[key]?.mandatory)) {
        if (!validationHelper[key].mandatoryValidation(val)) {
          hasErrors = true
          dispatch({
            type: Types.ERROR,
            payload: { key, errorType: ErrorTypes.MANDATORY }
          })
        }
      }
    })

    const errorObjectList = Object.entries(errors)
    hasErrors = hasErrors || errorObjectList.some(array => array[1].error)

    if (!hasErrors) {
      const newData = { ...data }
      newData.phoneNumber = newData.phoneNumber.replace(/[^\d]/g, "")
      reduxDispatch(sendSignUp(geral.language, newData))
    }
  }

  function dispatcherHelper(key, value) {
    dispatch({
      type: Types.DATA,
      payload: { [key]: value, key },
    })

  }

  const validateFunctions = {
    confirmEmail: (confirmEmail) => confirmEmail === state.email
  }

  function updateField({ target }) {
    const { name, value } = target
    dispatcherHelper(name, value)
    const mustValidate = (name !== 'document' || useDocument) || state.country === 'BR'
    if (mustValidate) {
      const validationFunc =
        validationHelper[name].validation
        || validateFunctions[name]

      if (validationFunc) {
        if (!validationFunc(value)) {
          dispatch({
            type: Types.ERROR,
            payload: { key: name, errorType: ErrorTypes.INVALID },
          })
        } else {
          dispatch({
            type: Types.VALID,
            payload: { key: name },
          })
        }
      }
    }
  }

  if (auth.signupToken) {
    return <Redirect to="/auth/signup/pincode" />
  }

  if (auth.passwordToken) {
    return <Redirect to="/auth/set-password" />
  }

  if (auth.signUpError) {
    return <Redirect to="/signup/account-already-exist" />
  }

  if (geral.countries === null || geral.countries.length === 0) {
    return <Loading />
  }

  function handleCountry(val) {
    const name = 'country'
    const { value } = val
    dispatcherHelper(name, value)


    const validationFunc =
      validationHelper[name].validation
      || validateFunctions[name]

    if (validationFunc) {
      if (!validationFunc(value)) {
        dispatch({
          type: Types.ERROR,
          payload: { key: name, errorType: ErrorTypes.INVALID },
        })
      } else {
        dispatch({
          type: Types.VALID,
          payload: { key: name },
        })
      }
    }
    setCountry(val)
  }

  const onChangeCaptcha = _captcha => {
    setCaptchaValue(_captcha)
  }

  return (
    <section>
      <Auth>
        <header className="pvt-content-header custom">
          <h2 className="pvt-content-title custom">{t('signup.title')}</h2>
        </header>
        {/* <Field>
          <Label label={t('yourRequests.label.country')} mandatory={true} />
          <Select
            placeholder=""
            onChange={handleCountry}
            isSearchable={false}
            value={country}
            options={countries}
            className={`combo-paises${state.errors.country.error ? ' error' : ''}`}
          />
          {
            state.errors.country.error
              ? <div className="error-message custom">{t(ErrorMessages.country[state.errors.country.type])}</div>
              : null
          }
        </Field> */}

        <Field>
          <Label label={t('signup.label.name')} relation="name-field" mandatory={true} />
          <Input
            id="name-field"
            type="text"
            name="name"
            onChange={updateField}
            invalidMessage={state.errors.name.error ? t(ErrorMessages.name[state.errors.name.type]) : null}
            maxLength={50}
          />
        </Field>

        <Field>
          <Label label={t('signup.label.email')} relation="email-field" mandatory={true} />
          <Input id="email-field" type="email" name="email" onChange={updateField}
            invalidMessage={state.errors.email.error ? t(ErrorMessages.email[state.errors.email.type]) : null}
          />
        </Field>

        <Field>
          <Label label={t('signup.label.confirmEmail')} relation="confirmEmail-field" mandatory={true} />
          <Input id="confirmEmail-field" type="email" name="confirmEmail" onChange={updateField}
            invalidMessage={state.errors.confirmEmail.error ? t(ErrorMessages.confirmEmail[state.errors.confirmEmail.type]) : null}
          />
        </Field>
        {useDocument &&
          <Field>
            <Label label={t('signup.label.document.not.dot')} relation="document-field" mandatory={true} />
            <Input id="document-field" type="number" name="document" onChange={updateField}
              invalidMessage={state.errors.document.error ? t(ErrorMessages.document[state.errors.document.type]) : null}
            />
          </Field>
        }

        {/* <Field>
          <Label label={t('signup.label.phoneNumber')} relation="phone-number-field" />
          <Input mask="(99)99999-9999" id="phone-number-field" type="tel" name="phoneNumber" onChange={updateField}
            invalidMessage={state.errors.phoneNumber.error ? t(ErrorMessages.phoneNumber[state.errors.phoneNumber.type]) : null}
          />
        </Field>*/}

        <Field className="singup-recaptcha">
          <ReCAPTCHA sitekey={captchakey} onChange={onChangeCaptcha} hl={systemLang.lang} key={systemLang.lang} />
        </Field>

        <Button
          type="confirm"
          label={t('signup.button.label.send')}
          classie="full"
          disabled={!captchaValue}
          onClick={submitForm}
        />
      </Auth>
    </section>
  )
}

export default React.memo(SignUp)
