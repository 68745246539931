import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

import { Creators as preferencesCreators } from './preferences.reducer'


const endPoints = window.endPoints

export function getPreferences(lang) {

    return (dispatch) => {

        const requestOptions = {
            method: 'GET',
            url: `${endPoints.api.url}/${lang}/${endPoints.api.preferences}?d=${new Date().getTime()}`,
            headers: {
              Authorization: `Bearer ${UserStore.getToken()}`
            },
        }

        dispatch(preferencesCreators.fetching(true))
        axiosInstance(requestOptions)
            .then(({ data }) => dispatch(preferencesCreators.getPreferences(data)))
            .catch(() => dispatch(preferencesCreators.getPreferences(null)))

    }
}

export function updatePreference({hash, value}) {

    const data = new FormData()
    data.append('hashes', hash)
    data.append('values', value)

  return (dispatch, getState) => {

    //gambeta pra já atualizar as preferences e não parecer que está errado o status no front
    const preferences = getState().preferences?.preferences;
    if (preferences) {
      const newPreferences = preferences.map( (groupPref) => {
        const newGroupPref = {...groupPref}
        if (groupPref && groupPref.consents) {
          newGroupPref.consents = groupPref.consents.map( (cons) => {
            const newCons = {...cons}
            if (newCons.hash === hash) {
              newCons.userState = value;
            }
            return newCons
          });
        }
        return newGroupPref;
      });
      dispatch(preferencesCreators.getPreferences(newPreferences))
    }
    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.updatePreferences}`,
      headers: { Authorization: `Bearer ${UserStore.getToken()}` },
      data,
    }

    axiosInstance(requestOptions)
      .catch(() => dispatch(preferencesCreators.getPreferences(null)) )
  }
}