
import { isEmail } from 'validator'

export const validationHelper = {
    email: {
        validation: (email) => !!isEmail(email),
        mandatoryValidation: (value) => value && !!value.trim().length,
    },
    password: {
        validation: (pass) => pass.length > 3,
        mandatoryValidation: (value) => value && !!value.trim().length,
    },
}

export const initialState = {
    email: '',
    password: '',
    errors: {
        email: {
            error: false,
            //message: 'E-mail é obrigatório'
        },
        password: {
            error: false,
            //message: 'A senha é obrigatória'
        }
    },
}

export const Types = {
    DATA: 'create-account/data',
    ERROR: 'create-account/ERROR',
    VALID: 'create-account/VALID'
}

function reducer(state, { type, payload }) {
    switch (type) {
        case Types.DATA:
            const { key, ...rest } = payload
            return {
                ...state,
                ...rest,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: false
                    },
                },
            }
        case Types.ERROR: {
            const { key } = payload
            return {
                ...state,
                hasError: true,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: true
                    },
                },
            }
        }

        case Types.VALID: {
            const { key } = payload
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: false
                    },
                },
            }
        }

        default: {
            return state
        }
    }
}

export default reducer