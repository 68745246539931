import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
// routing stuff
import { useLocation, Link } from 'react-router-dom'
import routes from 'routes/routes'

import { TYPES } from '../../store/reducers/menu.reducer'
import UserStore from 'services/userLocalStorage'
import './styles.sass'

function Navigation() {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const location = useLocation()

	const { auth, menu, geral } = useSelector(store => store)
	const authApp = auth?.user.authApp || false
	const defaultClass = 'pvt-navigation custom'
	const showMenuClass = 'pvt-navivation__show-menu'
	const [classes, setClasses] = useState(defaultClass)
	const hideMenu = () => dispatch({ type: TYPES.HIDE_MENU })
	const scrollToTop = () => {
		if (location.pathname !== '/auth/verification')
			window.scrollTo(0, 0)
	}

	const handleAthorizedSelfDomain = () => {
		const selfDomains = (geral?.portalConfig?.selfDomains || []).map(item => item.domain)
		const userEmail = UserStore.getEmailData()
		let domainBool = false
		if (selfDomains?.length > 0) {
			if (userEmail?.includes("@")) {
				const domain = userEmail.split("@")
				const domainEmail = "@" + domain[1]
				domainBool = selfDomains.includes(domainEmail)
			} else if (!auth.isAuthenticated) {
				domainBool = true
			}
		}
		return domainBool
	}


	useEffect(() => {
		hideMenu()
		scrollToTop()
	}, [location])


	useEffect(() => {
		const _classes = menu.visible ? `${defaultClass} ${showMenuClass}` : defaultClass
		setClasses(_classes)
	}, [menu])



	const routesFilter = useMemo(() => {
		return routes.filter(route => {
			const { portalOpenBankingOk, showHomePage } = geral.portalConfig
			const routeBlock = []

			// Enquanto não carregou informações do portal 
			// Não exibe menus para evitar efeito mostra apaga
			if (showHomePage === undefined) return false

			if (!showHomePage) routeBlock.push('/')
			if (!portalOpenBankingOk) routeBlock.push('/data-share')
			let routeShow = ""
			if (route.name === "routes.label.selfService") {
				routeShow = handleAthorizedSelfDomain()
			} else {
				routeShow = !(routeBlock.findIndex(r => r === route.path) >= 0)
			}
			return routeShow
		})
	}, [routes, geral.portalConfig])

	return (
		<nav className={classes}>
			<ul>
				{routesFilter.map(({ name, path }) => (
					<React.Fragment key={name}>
						{name !== 'routes.label.yourPersonalData' || (geral.portalConfig?.allowUpdate === true && !authApp) ?
							<li className={location.pathname === path ? 'active' : null}>
								<Link to={path}>{t(name)}</Link>
							</li>
							: null}
					</React.Fragment>
				))}
			</ul>
		</nav>
	)

}

export default Navigation