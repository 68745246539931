import * as React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './styles.sass'

const upPath =
  'M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'

const downPath =
  'M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'

function Accordion({ expanded = false, children, header, chevron }) {
  const [isOpen, setOpen] = React.useState(expanded)

  function handleClick() {
    setOpen(!isOpen)
  }

  return (
    <div className="pvt-accordion custom">
      <motion.header
        initial={false}
        onClick={handleClick}
        className={`pvt-accordion-header custom ${isOpen ? 'toggled' : null}`}
      >
        <svg fill="currentColor" viewBox="0 0 20 20">
          {!isOpen ? (
            <motion.path fillRule="evenodd" clipRule="evenodd" d={downPath} />
          ) : (
            <motion.path fillRule="evenodd" clipRule="evenodd" d={upPath} />
          )}
        </svg>

        {typeof header === 'string' ? <i>{header}</i> : header}
      </motion.header>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2, ease: [0.5, 0, 0.5, 1] }}
            className="pvt-accordion-content custom"
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Accordion
