import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

import { Creators as requestsCreators } from './requests.reducer'

const endPoints = window.endPoints

export function getRequests(token) {
  return (dispatch) => {
    // console.log(`${endPoints.api.url}/${endPoints.api.requests}?d=${new Date().getTime()}`)
    const requestOptions = {
      method: 'GET',
      url: `${endPoints.api.url}/${endPoints.api.requests}?d=${new Date().getTime()}`,
      headers: {
        Authorization: `Bearer ${token ? token : UserStore.getToken()}`
      },
    }

    dispatch(requestsCreators.fetching(true))
    dispatch(requestsCreators.getRequests(null))

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(requestsCreators.getRequests(data))
        dispatch(requestsCreators.fetching(false))
      })
      .catch(() => {
        dispatch(requestsCreators.getRequests(null))
        dispatch(requestsCreators.fetching(false))
      })
  }
}

export function getRequestsTypes() {
  return (dispatch) => {
    const requestOptions = {
      method: 'GET',
      url: `${endPoints.api.url}/${endPoints.api.rights}?d=${new Date().getTime()}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
    }

    //dispatch(requestsCreators.fetching(true))

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(requestsCreators.getRequestsLabel(data))
        //dispatch(requestsCreators.fetching(false))
      })
      .catch(() => dispatch(requestsCreators.getRequestsLabel([])))
  }
}


export function sendRequests(params, callback, callbackError) {
  return (dispatch, getState) => {

    // const { geral } = getState()

    // const data = new FormData()
    // console.log('params.fields', params.fields)
    // data.append('message', params.message)
    // data.append('requestType', params.requestType)
    // data.append('fileData', params.fileData)
    // data.append('requestDetail', params.requestDetail)
    // data.append('language', params.languageCode)
    // data.append('fields', params.fields)

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.sendRequests}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
      // 
      data: params
    }

    dispatch(requestsCreators.sending(true))
    dispatch(requestsCreators.error(null))

    axiosInstance(requestOptions)
      .then((data) => {
        callback(data)
        dispatch(requestsCreators.error(null))
        dispatch(requestsCreators.sending(false))
      })
      .catch((error) => {
        //console.log({ error })
        dispatch(requestsCreators.sending(false))
        callbackError(error)
        //dispatch(requestsCreators.error(errorMsg))
      })
  }
}

export function sendMessage(params, callback, token) {
  return (dispatch, getState) => {

    //const { geral } = getState()

    const data = new FormData()
    data.append('message', params.message)
    data.append('fileData', params.fileData)
    data.append('requestId', params.requestId)
    //data.append('requestType', params.requestType)
    data.append('language', params.languageCode)

    const messageOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.sendMessage}`,
      headers: {
        Authorization: `Bearer ${token ? token : UserStore.getToken()}`
      },
      data,
    }

    dispatch(requestsCreators.sending(true))

    axiosInstance(messageOptions)
      .then((data) => {
        callback(data)
        dispatch(requestsCreators.sending(false))
      })
      .catch((error) => console.log({ error }))
  }
}