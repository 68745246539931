

const reg = new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%*]).{10,16}');
export const validationHelper = {
    password: {
        validation: (value) => { return !!value.length && (reg.test(value)) },
        mandatoryValidation: (value) => value && !!value.trim().length
    },
    confirmPassword: {
        validation: (password, confirmPassword) => password === confirmPassword,
    }
}


export const initialState = {
    password: '',
    confirmPassword: '',
    hasError: true,
    errors: {
        password: {
            error: false,
            //message: 'A senha é obrigatória'
        },
        confirmPassword: {
            error: false,
            //message: 'As senhas não coincidem'
        }
    },
}

export const Types = {
    DATA: 'create-account/data',
    ERROR: 'create-account/ERROR',
}

function reducer(state, { type, payload }) {
    switch (type) {
        case Types.DATA:
            const { key, ...rest } = payload
            return {
                ...state,
                ...rest,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: false
                    },
                },
            }
        case Types.ERROR: {
            const { key } = payload
            return {
                ...state,
                hasError: true,
                errors: {
                    ...state.errors,
                    [key]: {
                        ...state.errors[key],
                        error: true
                    },
                },
            }
        }
        default: {
            return state
        }
    }
}

export default reducer