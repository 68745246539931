import React, { useRef, useEffect } from 'react'
import './styles.sass'
import 'gridjs/dist/theme/mermaid.css'
import { Grid as Gridjs } from 'gridjs'
import { useTranslation } from 'react-i18next'


function Grid(props) {
  const wrapper = useRef(null)
  const { current: instance } = useRef(new Gridjs(props))

  useEffect(() => {
    instance.render(wrapper.current)
  }, [instance])


  return <div className="pvt-table custom" ref={wrapper} {...props} />
}

function Table(props) {
  const { t } = useTranslation()
  const language = {
    pagination: {
      previous: t('table.pagination.previous'),
      next: t('table.pagination.next'),
    },
  }
  return <Grid {...props} language={language} />
}

export default Table
