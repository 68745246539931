import React from 'react'
import './styles.sass'

import { Row } from 'components/Layout'

// routing stuff
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// store
import { useSelector } from 'react-redux'

function NotLogged() {
  const { t } = useTranslation()
  const {geral = {} } = useSelector((store) => store)

  return (
    <div className="pvt-notlogged-wrapper custom">
      <div className="pvt-notlogged custom">
        <i className="fas fa-user-lock"></i>
        <h3>{t('notLogged.needLogin')}</h3>
        <p>
          {t('notLogged.needLogin.info')}
        </p>
        <Row position={['y-center', 'x-center']}>
          {
            geral.portalConfig.authType ?
              <Link
                to="/signup"
                className="pvt-btn mr-20 custom"
              >
                {t('notLogged.signup')}
              </Link>
              : ''
          }
          <Link
            to="/signin"
            className="pvt-btn3 mr-10 custom"
          >
            {t('notLogged.signIn')}
          </Link>
        </Row>
      </div>
    </div>
  )
}

export default NotLogged
