import React from 'react'

import './styles.sass'


function Overlay({visible}) {

	if (!visible) {
		return null
	}
    return (
		<div className="overlay">
			<div className="overlay__inner">
				<div className="overlay__content"><span className="spinner"></span></div>
			</div>
		</div>
	)
}

export default Overlay