import produce from 'immer'
import { enableES5 } from "immer";
import { lsc } from 'services/availableLanguages';
import UserStore from 'services/userLocalStorage';
/* eslint-disable import/first */
enableES5();

export const Types = {
  FETCHING: 'geral/FETCHING',
  LANGUAGE: 'geral/LANGUAGE',
  PORTALCONFIG: 'geral/PORTALCONFIG',
  COUNTRIES: 'geral/COUNTRIES',
  PORTALNOTFOUND: 'geral/PORTALNOTFOUND',
  PORTALERROR: 'geral/PORTALERROR'
}

export const Creators = {
  language: (payload) => ({
    type: Types.LANGUAGE,
    payload,
  }),
  portalConfig: (payload) => ({
    type: Types.PORTALCONFIG,
    payload,
  }),
  fetching: (payload) => ({
    type: Types.FETCHING,
    payload,
  }),
  countries: (payload) => ({
    type: Types.COUNTRIES,
    payload,
  }),
  portalNotFound: (payload) => ({
    type: Types.PORTALNOTFOUND,
    payload
  }),
  portalError: (payload) => ({
    type: Types.PORTALERROR,
    payload
  }),
}

export const defaultPortalConfig = {
  languages: [],
  exclusionAllowed: false,
  allowUpdate: true,
  templates: {},
  websiteEncrypterId: "",
  companyName: ""
}

const initialState = {
  language: lsc(UserStore.getLanguage()),
  portalConfig: { ...defaultPortalConfig },
  portalNotFound: false,
  portalError: false,
  fetching: false,
  currentTemplate: {},
  countries: []
}

const getCurrentTemplate = (state) => {
  const { portalConfig, language } = state;

  /* marison - em alguns navegadores a language vem como pt-BR e no servidor vem com pt */
  let lang = lsc(language);
  // if(language && language.indexOf("-")>-1){
  //   lang=language.substring(0,2);
  // }    
  return (lang && portalConfig.templates.hasOwnProperty(lang))
    ? portalConfig.templates[lang]
    : {}
};

function geralReducer(state = initialState, action) {
  const { payload, type } = action
  return produce(state, (draft) => {
    switch (type) {
      case Types.FETCHING:
        draft.fetching = payload
        break
      case Types.LANGUAGE:
        draft.language = payload
        draft.currentTemplate = getCurrentTemplate(draft);
        break
      case Types.PORTALCONFIG:
        draft.fetching = false
        draft.portalConfig = payload
        draft.currentTemplate = getCurrentTemplate(draft);
        break
      case Types.COUNTRIES:
        draft.countries = payload
        break
      case Types.PORTALNOTFOUND:
        draft.fetching = false
        draft.portalConfig = defaultPortalConfig
        draft.portalNotFound = payload
        break
      case Types.PORTALERROR:
        draft.fetching = false
        draft.portalConfig = defaultPortalConfig
        draft.portalError = payload
        break
      default:
        return draft
    }
  })
}

export default geralReducer
