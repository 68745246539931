import { combineReducers } from 'redux'

import auth from './auth.reducer'
import preferences from './preferences.reducer'
import requests from './requests.reducer'
import requestsDetails from './requestsDetails.reducer'
import geral from './geral.reducer'
import personalData from './personal-data.reducer'
import menu from './menu.reducer'

const appReducer = combineReducers({
  auth,
  preferences,
  requests,
  requestsDetails,
  geral,
  personalData,
  menu
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
