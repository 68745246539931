import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

import { Creators as requestsDetailsCreators } from './requestsDetails.reducer'

const endPoints = window.endPoints

export function getRequestsDetails(params, token) {
  return (dispatch) => {

    const isDl = params.download || false;
    const _complete = params.complete || false;
    const _dossie = params.dossie || false;
    const _printPdfAndCsv = params.printPdfAndCsv || false;

    // console.log(`${endPoints.api.url}/${endPoints.api.requests}Details/${params.encryptedId}?dl=${isDl}&lg=${UserStore.getLanguage()}`)
    const requestOptions = {
      method: 'GET',
      url: `${endPoints.api.url}/${endPoints.api.requests}Details/${params.encryptedId}?dl=${isDl}&lg=${UserStore.getLanguage()}&dossie=${_dossie}&complete=${_complete}&printPdfAndCsv=${_printPdfAndCsv}`,
      headers: {
        Authorization: `Bearer ${token ? token : UserStore.getToken()}`
      },
    }

    dispatch(requestsDetailsCreators.fetching(true))
    dispatch(requestsDetailsCreators.getRequestsDetails(null))

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(requestsDetailsCreators.getRequestsDetails(data))
        dispatch(requestsDetailsCreators.fetching(false))
      })
      .catch(() => {
        dispatch(requestsDetailsCreators.getRequestsDetails(null))
        dispatch(requestsDetailsCreators.fetching(false))
      })
  }
}

export function getRequestsDetailsTypes(params) {
  return (dispatch) => {
    const requestOptions = {
      method: 'GET',
      url: `${endPoints.api.url}/${endPoints.api.requests}Details/${params.encryptedId}?dl=${params.download}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
    }

    //dispatch(requestsCreators.fetching(true))

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(requestsDetailsCreators.getRequestsDetailsLabel(data))
        //dispatch(requestsCreators.fetching(false))
      })
      .catch(() => dispatch(requestsDetailsCreators.getRequestsDetailsLabel([])))
  }
}


// export function sendRequestsDetails(params, callback, callbackError) {
//   return (dispatch, getState) => {

//     // const { geral } = getState()

//     const data = new FormData()
//     data.append('message', params.message)
//     data.append('requestType', params.requestType)
//     data.append('country', params.country)
//     data.append('fileData', params.fileData)
//     data.append('requestDetail', params.requestDetail)
//     data.append('language', params.languageCode)

//     const requestOptions = {
//       method: 'POST',
//       url: `${endPoints.api.url}/${endPoints.api.sendRequests}`,
//       headers: {
//         Authorization: `Bearer ${UserStore.getToken()}`
//       },
//       data,
//     }

//     dispatch(requestsCreators.sending(true))
//     dispatch(requestsCreators.error(null))

//     axiosInstance(requestOptions)
//       .then((data) => {
//         callback(data)
//         dispatch(requestsCreators.error(null))
//         dispatch(requestsCreators.sending(false))
//       })
//       .catch((error) => {
//         //console.log({ error })
//         dispatch(requestsCreators.sending(false))
//         callbackError(error)
//         //dispatch(requestsCreators.error(errorMsg))
//       })
//   }
// }

// export function sendMessage(params, callback) {
//   return (dispatch, getState) => {

//     //const { geral } = getState()

//     const data = new FormData()
//     data.append('message', params.message)
//     data.append('requestId', params.requestId)
//     //data.append('requestType', params.requestType)
//     data.append('language', params.languageCode)

//     const messageOptions = {
//       method: 'POST',
//       url: `${endPoints.api.url}/${endPoints.api.sendMessage}`,
//       headers: {
//         Authorization: `Bearer ${UserStore.getToken()}`
//       },
//       data,
//     }

//     dispatch(requestsCreators.sending(true))

//     axiosInstance(messageOptions)
//       .then((data) => {
//         callback(data)
//         dispatch(requestsCreators.sending(false))
//       })
//       .catch((error) => console.log({ error }))
//   }
// }