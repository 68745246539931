import ReactDOM from 'react-dom'
import { h, createRef as gCreateRef, Component as gComponent } from 'gridjs'

class Status extends gComponent {
  ref = gCreateRef(null)

  componentDidMount() {
    ReactDOM.render(this.props.element, this.ref.current)
  }

  render() {
    return h('div', { ref: this.ref })
  }
}

export { h }

export default Status
