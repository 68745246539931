import React, { useMemo } from 'react';
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next';

import Button from 'components/Button'

import './Popup.scss';

function PopupError ({ isOpen, onClose, onClickBack, errorCode }) {
  const { t } = useTranslation();

  const error = useMemo(() => {
    const defautText = t('label.datashare.popup.error.defautText');

    const errorInstabilitySystem = {
      title: t('label.datashare.popup.error.errorInstabilitySystem.title'),
      text: defautText
    }

    const errorCommunicationSystems = {
      title: t('label.datashare.popup.error.errorCommunicationSystems.title'),
      text: defautText
    }
    const errorList = {
      400: {
        title: t('label.datashare.popup.error.400.title'),
        text: t('label.datashare.popup.error.400.text')
      }, 
      401: errorInstabilitySystem,
      403: errorCommunicationSystems,
      404: {
        title: t('label.datashare.popup.error.404.title'),
        text: t('label.datashare.popup.error.404.text')
      },
      405: errorCommunicationSystems,
      406: errorCommunicationSystems,
      429: errorInstabilitySystem,
      500: errorInstabilitySystem,
      default: errorInstabilitySystem,
    }

    return errorList[errorCode] ? errorList[errorCode] : errorList.default
  }, [t, errorCode])
  

  return (
    <Popup
      open={isOpen}
      onClose={onClose}
    >
      <div className="popup popup-error">
        <h1>{error.title}</h1>

        <div className="popup-row popup-text">
          <p>{error.text}</p>
        </div>
        
        <Button
          type="default"
          label={t("button.label.back")}
          onClick={onClickBack}
          className='popup-confirm-button'
        />
      </div>
  </Popup>
  )
}

export default PopupError;