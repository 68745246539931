// import 'core-js/es/map';
// import 'core-js/es/set';
// import 'core-js/es/weak-set';
// import 'core-js/es/weak-map';
import 'core-js';
import 'raf/polyfill';
import VLibras from '@djpfs/react-vlibras';
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';

///store
import { Provider } from 'react-redux'
import store from './store'

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={ i18n }>
      <Provider store={store}>
        <App/>
        <VLibras/>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('privacy-tools-app'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
