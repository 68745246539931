import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pt from './locales/pt.json';  
import en from './locales/en.json';
import es from './locales/es.json';

// the translations
const resources = {
    en: {
      translations: en
    },
    pt: {
        translations: pt
    },
    es: {
        translations: es
    }

  };

i18n
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources,
        fallbackLng: 'pt',
        //debug: true,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;
