// Não acho interessante a utilização do MUI nesse projeto, porém, foi necessário desenvolver essa funcionalidade em caráter de urgência.
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from 'components/Button'
import { Loading } from 'components/Icons'
import { Row } from 'components/Layout'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

function Action(request, handleNewMessageClick, t) {
    return (
        <Button
            type="primary"
            label={t('label.question')}
            size="small"
            classie="lighter"
            disabled={false}
            onClick={() => handleNewMessageClick(request)}
        />)        
};

const RequestSelfService = () => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState()
    const TOKEN_EXTERNAL_KEY = 'privacy-tools-auth-token-external'
    const [creationRequests, setCreationRequests] = useState([])

    const getData = useCallback(async () => {
        setIsLoading(true)
        const token = UserStore.getToken()
        const config = { headers: { Authorization: `Bearer ${token}` } }

        try {
            const [{ data: creationRequestsData }] = await Promise.all([axiosInstance.get(`creation-request?portalId=${window.endPoints.api.portalID}`, config)])

            const table = creationRequestsData ? creationRequestsData.map((request) => ({
                id: request.id,
                name: request.name,
                description: request.description,
                code: request.code,
                respondents: request.respondents?.map((respondent) => ({
                    id: respondent.id,
                    createdAt: format(new Date(respondent.createdAt), 'dd/MM/yyyy HH:mm:ss'),
                    updatedAt: respondent.updatedAt ? format(new Date(respondent.updatedAt), 'dd/MM/yyyy HH:mm:ss') : '',
                    status: respondent.status,
                    requestId:request.id
                }))
            })) : [];
            setCreationRequests(table);

        } catch (error) {
            console.error({ error })
            setMessage(t('genericError'))
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
        getData()
    }, [getData]);

    const handleRefreshPageClick = () => {
        getData();
    };

    const handleQuestion = async (record) => {
        setIsLoading(true);

        const token = UserStore.getToken()
        const config = { headers: { Authorization: `Bearer ${token}` } }

        try {
            const timestampToAvoidCache = new Date().getTime()
            const [{ data: respondentUrl }] = 
                await Promise.all(
                    [
                        record.requestId?
                        axiosInstance.get(`creation-request/open-creation-request/${record.requestId}/${record.id}/?portalId=${window.endPoints.api.portalID}&tsp=${timestampToAvoidCache}`, config)
                        :
                        axiosInstance.get(`creation-request/open-creation-request/${record.id}/?portalId=${window.endPoints.api.portalID}&tsp=${timestampToAvoidCache}`, config)

                ])

            if (respondentUrl) {
                const token = respondentUrl.externalAccessToken;
                localStorage.setItem(TOKEN_EXTERNAL_KEY, respondentUrl.externalAccessToken)
                window.open(respondentUrl.url + "/portal/?token=" + token);
                handleRefreshPageClick();
            }
        } catch (error) {
            console.error({ error })
            setMessage(t('genericError'))
        } finally {
            setIsLoading(false)
        }
    };

    const renderRespondents = (respondents) => {
      if (respondents && respondents.length > 0) {
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
                {t('label.view.responses')}
            </AccordionSummary>
            <AccordionDetails>
              <table
                className='table table-striped table-hover col-sm-11'
                style={{ marginLeft: '20px', marginTop: '10px', width: '100%' }}
              >
                <thead>
                  <tr>
                    <th>{t('dm.default.creationDate')}</th>
                    <th>{t('dm.default.changeDate')}</th>
                    <th>{t('yourRequests.table.status')}</th>
                    <th>{t('label.question')}</th>
                  </tr>
                </thead>
                <tbody>
                  {respondents.map((respondent) => (
                    <tr key={respondent.id}>
                      <td>{respondent.createdAt}</td>
                      <td>{respondent.updatedAt}</td>
                      <td>{t(`label.${respondent.status}`)}</td>
                      <td>
                        {respondent.status === 'REPROVED' ||
                        respondent.status === 'SENT' ||
                        respondent.status === 'SAVED'
                          ? Action(respondent, handleQuestion, t)
                          : respondent.status === 'ANSWERED'
                          ? t('label.mapping.asset.no.status.portal')
                          : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        )
      }
      return null
    }

    if (isLoading) return <Loading />

    return (
        <section>
            <header className='pvt-content-header custom'>
                <h1
                    style={{
                        margin: '0 0 5px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: 'var(--txt-surface)',
                    }}
                >
                    {t('label.self.service.request.label')}
                </h1>
                <p className='pvt-content-desc custom'>{t('label.self.service.request.description')}</p>

                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        className="pvt-btn custom pvt-btn-primary undefined size-undefined"
                        type="primary"
                        label={t('yourRequests.button.refreshList')}
                        onClick={handleRefreshPageClick}
                    />
                </Row>

            </header>



            <div className='pvt-card pvt-card-100'>
                {creationRequests.length > 0 ? (
                    <table className="table table-striped table-hover pvt-table your-requests-table pvt-card pvt-txt-card pvt-card-100 custom">
                        <thead>
                            <tr>
                                <th>{t('label.self.service.request.label.name')}</th>
                                <th>{t('assessment.form.table.description')}</th>
                                <th>{t('label.code')}</th>
                                <th>{t('label.question')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creationRequests.map((request) => (
                                <>
                                    <tr key={request.id}>
                                        <td>{request.name}</td>
                                        <td>{request.description}</td>
                                        <td>{request.code}</td>
                                        <td>{Action(request, handleQuestion, t)}</td>
                                    </tr>
                                    {request.respondents && request.respondents.length > 0 ?
                                        <tr key={`child-${request.id}`}>
                                            <td colspan="4">
                                                {renderRespondents(request.respondents)}
                                            </td>
                                        </tr> : null}
                                </>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className='pvt-content-desc custom'>{t('label.no.requests.available')}</p>
                )}
            </div>

            <Popup open={message} onClose={() => setMessage()} closeOnDocumentClick={true} position='right center' modal>
                {(close) => (
                    <div className='popup'>
                        <div className='close'>
                            <a onClick={close}>&times;</a>
                        </div>
                        <div>{message}</div>
                    </div>
                )}
            </Popup>
        </section>
    )
}

export default RequestSelfService
