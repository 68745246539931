import React from 'react';
import { useSelector } from 'react-redux';

import User from 'components/User';
import { Row } from 'components/Layout';
import PortalError from 'views/PortalError'

import Footer from 'components/Footer'

import './styles.sass';

function Content({ children }) {
  const { auth, geral } = useSelector(store => store);
  const { portalConfig } = geral;
  const contentClass = 'pvt-route-content custom';
  const cssClasses = !auth.isAuthenticated
    ? `${contentClass} unauthenticated`
    : contentClass;

  if (geral.portalNotFound || geral.portalError) {
    return (
      <main className="pvt-main custom">
        <div className={cssClasses}>
          <PortalError messageKey={geral.portalNotFound ? 'portalNotFound.message' : 'portalError.message'} />
        </div>
      </main>
    )

  }

  return (
    <div className="pvt-main-root">
      <main className="pvt-main custom">
        <header className="pvt-main-header">
          <Row position={['x-between', 'y-center']}>
            <h1 className="pvt-d-block pvt-f-left y-center">{portalConfig.title}</h1>
            <User />
          </Row>
        </header>
        <div className={cssClasses}>
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Content;
