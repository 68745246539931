import * as React from 'react'

import './styles.sass'

function Alert({ type = 'disclaimer', icon, text, align, size }) {
  return (
    <div
      className={`pvt-alert custom pvt-alert-${type} pvt-alert-${align} pvt-alert-${size}`}
    >
      {icon}
      <p>{text}</p>
    </div>
  )
}

export default Alert
