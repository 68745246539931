import React from 'react';
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next';

import Button from 'components/Button'

import './Popup.scss';

function PopupAccept ({ opened, onClose, onConfirm }) {
  const { t } = useTranslation();
  return (
    <Popup
      open={opened}
      onClose={onClose}
    >
      <div className="popup popup-consent">
        <h1>{t("label.datashare.popup.accept.h1")}</h1>

        <p>{t("label.datashare.popup.accept.p")}</p>
        <br/>
        <br/>
        <p>{t("label.datashare.popup.accept.p2")}</p>

        <div className="popup-row popup-row-buttons">
          <Button
            type="default"
            label={t("button.label.back")}
            onClick={onClose}
          />

          <Button
            type="Primary"
            label={t("button.label.share")}
            onClick={onConfirm}
          />
        </div>

      </div>
  </Popup>
  )
}

export default PopupAccept;