
class HandleUtils {

    static generateEmailHatched = (email) => {
        var stringfiedSplit = email ? email.split('@') : ""
        if (stringfiedSplit?.length === 2) {
            var initialEmail = stringfiedSplit[0]
            var domain = stringfiedSplit[1]
            var hatchedEmail = initialEmail.substring(0, 2) + new String('***', initialEmail.length - 2)
            return hatchedEmail + "@" + domain
        } else {
            return email
        }
    }

    static getUserInfoByToken = (tokenData) => {
        if (!tokenData) return null
        const tokenParse = JSON.parse(atob(tokenData.split('.')[1]))
        const userInfo = JSON.parse(tokenParse.sub)
        return userInfo
    }
}

export default HandleUtils

