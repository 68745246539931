import { Loading } from 'components/Icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { axiosInstance as api } from 'services/api'
import UserStore from 'services/userLocalStorage'
import './styles.sass'

function AssessmentPortal() {
  const { t } = useTranslation()
  const { auth } = useSelector((store) => store)
  const [loading, setLoading] = useState(false)
  const [, setMessage] = useState({})
  const [forms, setForms] = useState([])
  const portalId = window.endPoints.api.portalID

  let token = auth.signinToken == null ? UserStore.getToken() : auth.signinToken

  const sendToConsentPersonRespondent = async (id) => {
    setLoading(true)
    setMessage({})
    try {
      const { data: { url, externalAccessToken: externalToken } = {} } = await api.post(
        `/selfservice/forms/send/${portalId}/${id}`,
        { formEncryptedId: id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      window.open(`${url}?token=${externalToken}`)
      setMessage({ type: 'success', message: t('edit.success.save') })
    } catch (error) {
      console.error({ error })
      setMessage({ type: 'error', message: t('genericError') })
    }
    setLoading(false)
  }

  const getForms = useCallback(async () => {
    setLoading(true)
    setMessage({})
    const requestOptions = {
      method: 'POST',
      url: `/selfservice/forms/${portalId}`,
      headers: { Authorization: `Bearer ${token}` },
      data: { formType: 'PORTAL', limit: 1000 },
    }
    try {
      const { data: { content: assessmentListData } = {} } = await api(requestOptions)
      setForms(assessmentListData)
    } catch (error) {
      console.error({ error })
      setMessage({ type: 'error', message: t('genericError') })
    }
    setLoading(false)
  }, [portalId, t, token])

  useEffect(() => {
    getForms()
  }, [getForms])

  return (
    <section>
      <header className='pvt-content-header custom'>
        <h1
          style={{
            margin: '0 0 5px',
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'var(--txt-surface)',
          }}
        >
          {t('label.self.service.assessment.create')}
        </h1>
        <p className='pvt-content-desc custom'>{t('label.self.service.assessment.description')}</p>
      </header>
      <div className='pvt-card pvt-card-100'>
        {loading ? (
          <Loading />
        ) : (
          <div className='dsar-form'>
            {forms.length > 0 ? (
              <div className='table-responsive'>
                <table className='table table-striped table-hover col-sm-12'>
                  <thead>
                    <tr>
                      <th>{t('assessment.form.table.title')}</th>
                      <th>{t('assessment.form.table.description')}</th>
                      <th>{t('assessment.form.table.action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {forms.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td className='td-buttons'>
                          <button
                            type='button'
                            title={t('assessment.form.table.button.respondent')}
                            onClick={() => sendToConsentPersonRespondent(item.id)}
                            className='btn btn-info'
                          >
                            <i className='fas fa-reply' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className='pvt-content-desc custom'>{t('assessment.form.assessment.respondent.not.found')}</p>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default AssessmentPortal
