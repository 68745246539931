import axios from 'axios'
/*import { endPoints } from './api'*/
import UserLocalStorage from './userLocalStorage'
const endPoints = window.endPoints;
async function handleError(error) {
  console.log('invalid token')

  if (error.response.status !== 401) {
    return new Promise((_, reject) => reject(error))
  }

  // Logout user if we can't get a new token refresh
  if (
    error.config.url === endPoints.api.refreshToken ||
    error.response.message === 'Conta desabilitada.'
  ) {
    UserLocalStorage.clear()
    return new Promise((_, reject) => reject(error))
  }

  // Try request again with new tokenr
  return UserLocalStorage.getNewToken()
    .then((token) => {
      const config = error.config
      config.headers['Authorization'] = `Bearer ${token}`

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    })
    .catch((error) => Promise.reject(error))
}

export default handleError
