import React from 'react';
import { useTranslation } from 'react-i18next'
import Alert from 'components/Alert'
import Field from 'components/Form/Field'

function PortalError({messageKey}) {
  const { t } = useTranslation()

  const message = messageKey ? t(messageKey) : t('portalNotFound.message');

    return(
        <section>
            <div className="pvt-msg-space">
                <Field>
                    <Alert
                        align="center"
                        size="full"
                        type="error"
                        text={
                            <>
                            <b>{ message }</b>
                            </>
                        }
                    />
                </Field>
            </div>
        </section>
    );
}

export default PortalError;