import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Auth from 'components/Auth';

import Alert from 'components/Alert';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import Input from 'components/Form/Input';
import Button from 'components/Button';
import { Row } from 'components/Layout';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { signUpPinCode } from 'store/reducers/auth.thunks';

import { axiosInstance } from 'services/api'

// routing stuff
import { Redirect } from 'react-router-dom';
import Loading from 'components/Icons/Loading';
import Popup from 'reactjs-popup';

const endPoints = window.endPoints;

function LoginPinCode() {

  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const { auth, geral } = useSelector(state => state);

  const [pincode, setPincode] = React.useState(null);
  const [error, setError] = React.useState(true);
  const [isSending, setIsSending] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  function submitForm() {
    if (!pincode || notOnlyNumbers(pincode)) {
      setError(true)
      return
    }

    reduxDispatch(signUpPinCode(pincode))
  }

  function notOnlyNumbers(pin) {
    var numberPattern = /^\d+$/g;
    return !pin.trim().match(numberPattern)
  }

  function onChange({ target }) {
    setPincode(target.value)
    setError(notOnlyNumbers(target.value));
    return <Redirect to="/auth/set-password" />
  }

  function tryAgain() {
    // reduxDispatch(authCreators.signup(null))

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${geral.language}/${endPoints.api.resendSignupPincode}`,
      headers: { Authorization: `Bearer ${auth.signupToken}` },
    };

    setIsSending(true)

    axiosInstance(requestOptions)
      .then(({ data }) => setShowMessage(true))
      .catch(error => console.log(error))
      .finally(() => setIsSending(false))
  };

  function handleClosePopup() {
    setShowMessage(false);
  }

  if (auth.passwordToken) {
    return <Redirect to="/auth/set-password" />
  }

  if (!auth.signupToken) {
    return <Redirect to="/signup" />
  }

  return (
    <section>
      <Auth>
        <header className="pvt-content-header custom">
          <h1 className="pvt-content-title custom">{t('signupPinCode.title')}</h1>
        </header>
        <Field>
          <Alert
            align="center"
            size="full"
            text={
              <>
                <b>{t('signupPinCode.alert.sendCode')}</b>
              </>
            }
          />
        </Field>
        {auth.error ?
          <Field>
            <Alert
              align="center"
              size="full"
              type="error"
              text={
                <>
                  <b>{t('signupPinCode.alert.codeInvalid')}</b>
                </>
              }

            />
          </Field>
          : null
        }
        <Field>
          <Label label={t('signupPinCode.label.code')} relation="password-field" />
          <Input id="password-field" onChange={onChange} type="number"
            invalidMessage={error && pincode ? t('signupPinCode.validation.error.code') : null} />
        </Field>

        <Field>
          <Button
            type="confirm"
            label={t('button.label.send')}
            classie="full"
            onClick={submitForm}
            disabled={error}
          />
        </Field>

        <Field>
          {
            isSending
              ? <Loading />
              : <Row position={['x-center']}>
                <Button
                  className='pvt-btn2 txt-al-center'
                  type="ghost"
                  label={t('signupPinCode.button.resendCode')}
                  onClick={tryAgain}
                  size="small"
                />
              </Row>
          }
        </Field>
      </Auth>

      <Popup open={showMessage} onClose={handleClosePopup} closeOnDocumentClick={true} position="right center" modal>
        {close =>
          <div className="popup">
            <div className="close">
              <a onClick={close}>&times;</a>
            </div>
            <div>{t('signupPinCode.alert.resendCode')}</div>
          </div>
        }
      </Popup>
    </section>
  )
}

export default LoginPinCode
