import * as React from 'react'

function Button({ label, type, icon = null, classie, size, className, children, ...props }) {
  return (
    <button
      type="button"
      className={`pvt-btn custom pvt-btn-${type} ${classie} size-${size} ${className ? className : ''}`}
      {...props}
    >
      {children}
      {icon}
      {label}
    </button>
  )
}

export default Button
