import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';

const DataShareStages = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (stage) => (event, isExpanded) => {
    setExpanded(isExpanded ? stage : false);
  };

  return (
    <Dialog maxWidth='md' fullWidth {...props}>
      <DialogTitle>Saiba mais sobre o Open Finance e suas fases:</DialogTitle>
      <DialogContent>
        <Accordion elevation={0} expanded={expanded === 'stage1'} onChange={handleChange('stage1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="stage1bh-content"
            id="stage1bh-header"
          >
            <Typography>
              O que é Open Finance
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              &bull; O Open Finance é a possibilidade de clientes de produtos e serviços financeiros permitirem o compartilhamento de suas informações entre diferentes instituições autorizadas pelo Banco Central, de forma ágil, segura e conveniente.
            </Typography>
            <Typography>
              &bull; Com a permissão do cliente, as instituições financeiras se conectam diretamente às plataformas de outras instituições participantes e acessam exatamente os dados autorizados pelos clientes. Todos esse processo é feito em um ambiente seguro e realizado apenas com a permissão do cliente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded === 'stage2'} onChange={handleChange('stage2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="stage2bh-content"
            id="stage2bh-header"
          >
            <Typography>Fase 1 – 01/02/2021</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              &bull; O Open Finance começa com as instituições participantes disponibilizando ao público informações padronizadas sobre os seus canais de atendimento e as características de produtos e serviços bancários tradicionais que oferecem. Nessa fase, não será compartilhado nenhum dado de cliente.
            </Typography>
            <Typography>
              &bull; Com isso, podem surgir soluções que comparam diferentes ofertas de produtos e serviços financeiros, auxiliando as pessoas a escolherem a opção mais adequada ao seu perfil e necessidades. Entre as possíveis soluções que podem surgir estão os comparadores de tarifas bancárias, de tipos de contas e de cartões de crédito.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded === 'stage3'} onChange={handleChange('stage3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="stage3bh-content"
            id="stage3bh-header"
          >
            <Typography>Fase 2 – 13/08/2021</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              &bull; A partir dessa fase, os clientes, se quiserem, poderão solicitar o compartilhamento entre instituições participantes de seus dados cadastrais, de informações sobre transações em suas contas, cartão de crédito e produtos de crédito contratados. É preciso reforçar que o compartilhamento ocorre apenas se a pessoa autorizar, sempre para finalidades determinadas e por um prazo específico. E será possível para o cliente cancelar essa autorização a qualquer momento em qualquer das instituições envolvidas no compartilhamento.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              &bull; Como principal benefício, será possível aos clientes receber ofertas de produtos e serviços mais adequados ao seu perfil, a custos mais acessíveis e de forma mais ágil e segura. Também poderão surgir soluções mais personalizadas de gestão e de aconselhamento sobre finanças pessoais, por exemplo. O ecossistema financeiro como um todo também ganha com mais inovação, maior competitividade e com a racionalização de processos.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              &bull; <b>Importante!</b> A implementação dessa etapa será escalonada, de forma a garantir segurança e estabilidade ao processo e permitir ajustes que forem necessários. Entre 13/08 e 24/10, haverá limites para:
            </Typography>
            <Typography sx={{ ml: 2 }}>
              <ul>
                <li>&bull; a quantidade de autorizações para o compartilhamento;</li>
                <li>&bull; o horário para o compartilhamento;</li>
                <li>&bull; o tipo de informação a ser compartilhada.</li>
                <li>&bull; A cada novo período esses limites aumentam até a operação completa dessa fase que ocorrerá após o último período.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded === 'stage4'} onChange={handleChange('stage4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="stage4bh-content"
            id="stage4bh-header"
          >
            <Typography>Fase 3 – 29/10/2021</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              &bull; Nessa fase, surge a possibilidade de compartilhamento dos serviços de iniciação de transações de pagamento e de encaminhamento de proposta de operação de crédito.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              &bull; Isso abre caminho para o surgimento de novas soluções e ambientes para a realização de pagamentos e para a recepção de propostas de operações de crédito, possibilitando o acesso a serviços financeiros de forma mais fácil, célere e por meio de canais mais convenientes para o cliente, preservando a segurança do processo. Vale lembrar que também nesses casos o compartilhamento só acontece com a autorização prévia e específica do cliente.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              &bull; Com relação às formas de pagamento, novas possibilidades poderão ser iniciadas via Open Finance a partir de datas específicas. Veja abaixo:
            </Typography>
            <Typography sx={{ ml: 2 }}>
              <ul>
                <li>&bull; 29/10/21 - Pagamento com PIX </li>
                <li>&bull; 15/02/22 - Pagamentos com TED e transferência entre contas na mesma instituição</li>
                <li>&bull; 30/06/22 - Pagamento de boletos </li>
                <li>&bull; 30/09/22 - Pagamentos com débito em conta </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} expanded={expanded === 'stage5'} onChange={handleChange('stage5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="stage5bh-content"
            id="stage5bh-header"
          >
            <Typography>Fase 4 – 15/12/2021</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              &bull; Dados sobre outros serviços financeiros passam a fazer parte do escopo do Open Finance. Os clientes – sempre que quiserem e autorizarem - poderão compartilhar suas informações de operações de câmbio, investimentos, seguros, previdência complementar aberta e contas-salário, bem como acessar informações sobre as características dos produtos e serviços com essa natureza disponíveis para contratação no mercado.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              &bull; Assim, amplia-se ainda mais a possibilidade de surgimento de novas soluções para a oferta e a contração de produtos e serviços financeiros, mais integrados, personalizados e acessíveis, sempre com o consumidor no centro das decisões.
            </Typography>
            <Typography>
              &bull; A implementação será gradativa de acordo com o calendário estabelecido pelo Banco Central do Brasil
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Link sx={{ float: 'right', m: 2, color: 'var(--primaryColor)' }} target='_blank' rel="noopener noreferrer" href='https://www.bcb.gov.br/estabilidadefinanceira/openbanking'>Saiba mais</Link>
      </DialogActions>
    </Dialog>
  )
}

export default DataShareStages
