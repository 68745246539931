import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

import Auth from 'components/Auth'
import Alert from 'components/Alert';
import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import { Loading } from 'components/Icons'
import { validPinCode, getTokenByProtocolAndEmail } from './api';

function FormPinCode({ setTokenByProtocol, formProtocolData }) {
  const { t } = useTranslation()
  const { geral: { language } = {} } = useSelector(state => state)

  const [isSending, setIsSending] = useState(false)
  const [pin, setPin] = useState({
    code: '',
    error: null,
  });

  const hasErrors = !!pin.error || !pin.code

  async function submitForm() {
    if (!hasErrors) {
      try {
        
        setIsSending(true)
        const { token } = formProtocolData
        const data = await validPinCode({ token, pincode: pin.code })
        setIsSending(false)
        setTokenByProtocol(data)
      } catch (error) {
        setIsSending(false);
        const [invalid, param] = error.split(' ')
        let errorMessage = ''
        if (invalid === 'Invalid') {
          errorMessage = `findRequestByProtocol.error.invalid.${param}`
        } else {
          errorMessage = 'findRequestByProtocol.error.generic'
        }
        setPin(({ code }) => ({ code, error: errorMessage }))
      }
    }
  }

  const resendCode = async () => {
    try {
      setIsSending(true);
      await getTokenByProtocolAndEmail(language, formProtocolData)
      setIsSending(false);
      setPin(({ code }) => ({ code, reSend: true }))
    } catch (error) {
      setIsSending(false);
      setPin(({ code }) => ({ code, error: 'findRequestByProtocol.error.generic' }))
    }
  }

  return (
    <Auth>
      <header className="pvt-content-header custom">
        <h1 className="pvt-content-title custom">{t('signupPinCode.title')}</h1>
      </header>
      <Field>
        <Alert
          align="center"
          size="full"
          text={<b>{t('signupPinCode.alert.sendCode')}</b>}
        />
      </Field>

      {pin.reSend && (
        <Field>
          <Alert
            align="center"
            size="full"
            type="success"
            text={<b>{t('findRequestByProtocol.reSend.pinCode')}</b>}
          />
        </Field>
      )}

      {pin.error && (
        <Field>
          <Alert
            align="center"
            size="full"
            type="error"
            text={<b>{t(pin.error)}</b>}
          />
        </Field>
      )}

      <Field>
        <Label label={t('signupPinCode.label.code')} relation="password-field" />
        <Input
          id="pincode-field"
          onChange={({ target: { value: code } }) => setPin({ code })}
          type="number"
          defaultValue={pin.code}
          invalidMessage={pin.error && pin.code ? t('signupPinCode.validation.error.code') : null}
        />
      </Field>

      {isSending ? (
        <Loading />
      ) : (
        <>
          <Field>
            <Button
              type="confirm"
              label={t('button.label.send')}
              classie="full"
              onClick={submitForm}
              disabled={pin.error || pin.code.length < 6}
            />
          </Field>
          <Field>
            <Button
              className='pvt-btn2 txt-al-center'
              type="ghost"
              label={t('signupPinCode.button.resendCode')}
              onClick={resendCode}
              size="small"
            />
          </Field>
        </>
      )}
    </Auth>
  )
}

export default React.memo(FormPinCode)