import Button from 'components/Button/Button';
import React from 'react';

import GOOGLE from '../../img/sso/logo-google.svg'
import LINKEDIN from '../../img/sso/logo-linkedin.svg'
import FACEBOOK from '../../img/sso/logo-facebook.svg'
import GOV_BR from '../../img/sso/logo-gov_br.svg'
import CUSTOM_OAUTH2 from '../../img/sso/logo-custom_oauth2.svg'
import { useTranslation } from 'react-i18next';

const logoMap = {
  GOOGLE,
  LINKEDIN,
  FACEBOOK,
  GOV_BR,
  CUSTOM_OAUTH2
};

const SignInSSO = (
  {
    isDisabled = true,
    ssoProvider = { provider: '', providerLabel: '', logonUrl: '', requestParam: '', type: 'OPENID' },
  }) => {

  const { t } = useTranslation()

  const svg = logoMap[ssoProvider.provider] ?? logoMap.CUSTOM_OAUTH2

  const customWidth = ssoProvider.provider === 'GOV_BR' ? 64 : 32

  const handleOnClick = () => {
    formOpenIdSubmit(ssoProvider.logonUrl, ssoProvider.requestParam);
  }

  const getParamsSsoOpenIdObj = (encodeRequestParam) => {
    try {
      const plaintext = atob(encodeRequestParam)
      return JSON.parse(plaintext)
    } catch (error) {
      console.error('Error decrypt', error)
      return null
    }
  }

  const formOpenIdSubmit = (logonUrl, requestParam) => {

    try {

      const { client_id, nonce, response_type,
        redirect_uri, scope } = getParamsSsoOpenIdObj(requestParam);

      // Create a form dynamically
      const form = document.createElement("form");
      form.setAttribute("name", "formOpenId");
      form.setAttribute("method", "post");
      form.setAttribute("action", logonUrl);
      form.style.display = 'none';

      // Create input elements
      const param1 = document.createElement("input");
      param1.setAttribute("type", "hidden");
      param1.setAttribute("name", "client_id");
      param1.setAttribute("value", client_id);

      const param2 = document.createElement("input");
      param2.setAttribute("type", "hidden");
      param2.setAttribute("name", "nonce");
      param2.setAttribute("value", nonce);

      const param3 = document.createElement("input");
      param3.setAttribute("type", "hidden");
      param3.setAttribute("name", "response_type");
      param3.setAttribute("value", response_type);

      const param4 = document.createElement("input");
      param4.setAttribute("type", "hidden");
      param4.setAttribute("name", "redirect_uri");
      param4.setAttribute("value", redirect_uri);

      const param5 = document.createElement("input");
      param5.setAttribute("type", "hidden");
      param5.setAttribute("name", "scope");
      param5.setAttribute("value", scope);

      // create a submit button
      const btnSubmit = document.createElement("input");
      btnSubmit.setAttribute("type", "submit");
      btnSubmit.setAttribute("value", "Submit");

      // append items in form
      form.appendChild(param1);
      form.appendChild(param2);
      form.appendChild(param3);
      form.appendChild(param4);
      form.appendChild(param5);
      form.appendChild(btnSubmit);

      // append form in body
      document.getElementsByTagName("body")[0].appendChild(form);

      // send and remove    
      form.submit();
      document.body.removeChild(form);
    } catch (error) {
      console.error("formOpenIdSubmit");
      console.error(error);
      alert(t('signup.validation.error.sso.person.unauthorized'))
    }

  }

  return (
    <Button
      disabled={isDisabled}
      type="confirm"
      classie="full"
      onClick={handleOnClick}
      style={{ marginBottom: 16, background: '#fdfdfd', color: 'black' }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <img src={svg} alt={`Logo ${ssoProvider.providerLabel}`} style={{ height: 32, width: customWidth }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ marginLeft: 8 }}>{ t('signin.button.label.send.with') + ssoProvider.providerLabel}</p>
        </div>
      </div>
    </Button>
  );
};

export default SignInSSO;