import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Switch from 'components/Switch'
import Accordion from 'components/Accordion'
import { Row } from 'components/Layout'

// store
import {
  getPreferences,
  updatePreference
} from 'store/reducers/preference.thunks'
import {useDispatch, useSelector} from 'react-redux'

function PreferencesAccordion({ consent, children, alwaysActive }) {
  const { t } = useTranslation()
  const [showOld, setShowOld] = React.useState(true)
  const { auth, preferences, geral = {} } = useSelector((store) => store)
  const reduxDispatch = useDispatch()

  function savePreference(data) {
    setShowOld(false);
    const updateConsent = updatePreference({
      hash: consent.hash,
      value: data,
    })
    reduxDispatch(updateConsent)
    setTimeout(() => {
      reduxDispatch(getPreferences(geral.language))
    }, 500);
  }

  function savePreferenceDefault(ev, data) {
    ev.preventDefault();
    ev.stopPropagation();
    savePreference(data);
    setShowOld(false);
  }

  return (
    <Accordion
      header={
        <Row position={['y-center', 'x-between']}>
          <i className="consent-title">{consent.title}</i>{' '}
          {
            consent.old && showOld ?
              <>
                <i className="title-hold" style={{ color: 'red' }}>{t('preferences.isOld')}</i>&nbsp;&nbsp;
                <button title={t('preferences.isOld.help')} type="button" className="pvt-btn pvt-btn-primary btn-hold" onClick={ev => savePreferenceDefault(ev, consent.userState)} style={{ padding: '3px' }}>{t('preferences.isOld.hold')}</button>
              </>
              : null
          }
          <Switch
            id={consent.hash}
            checked={consent.userState}
            callback={savePreference}
            label={consent.userState ? t('label.active') : t('label.deactivated')}
            disabled={alwaysActive}
          />
        </Row>
      }
    >
      {children}
    </Accordion>
  )
}

export default PreferencesAccordion
