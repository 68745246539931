import produce from 'immer'
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

export const Types = {
    FETCHING: 'requests/FETCHING',
    GETREQUESTS: 'requests/GETREQUESTS',
    GETREQUESTSLABEL: 'requests/GETREQUESTSLABEL',
    SENDING: 'requests/SENDREQUESTS',
    ERROR: 'requests/ERROR',
}

export const Creators = {
    fetching: (payload) => ({
        type: Types.FETCHING,
        payload,
    }),
    sending: (payload) => ({
        type: Types.SENDING,
        payload,
    }),
    getRequests: (payload) => ({
        type: Types.GETREQUESTS,
        payload,
    }),
    getRequestsLabel: (payload) => ({
        type: Types.GETREQUESTSLABEL,
        payload,
    }),
    error: (payload) => ({
      type: Types.ERROR,
      payload,
    }),

}

const initialState = {
  fetching: false,
  requests: [],
  requestsLabel: [],
  error: null,
}

function requestsReducer(state = initialState, action) {
    const { payload, type } = action
    // produce simplifies the immutability.. <3
    return produce(state, (draft) => {
        switch (type) {
          case Types.FETCHING:
            draft.fetching = payload
            draft.error = null
            break
          case Types.GETREQUESTS:
            //draft.fetching = false
            draft.requests = payload
            break
          case Types.SENDING:
            draft.sending = payload
            break
          case Types.GETREQUESTSLABEL:
            draft.requestsLabel = payload
            break
          case Types.ERROR:
            draft.fetching = false
            draft.error = payload
            break
          default:
            return draft
        }
    })
}



export default requestsReducer
