import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import { useSelector } from 'react-redux';

import NotLogged from 'components/NotLogged';

import DataShareList from './DataShareList';
import DataShareDetail from './DataShareDetail';

function DataShare() {
  const match = useRouteMatch();
  const { auth = {} } = useSelector((store) => store);

  if (!auth.isAuthenticated) {
    return (
      <section className="pvt-your-request custom">
        <NotLogged />
      </section>
    )
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/detail/:id`}>
        <DataShareDetail />
      </Route>
      <Route exact path={`${match.path}/detail`}>
        <DataShareDetail />
      </Route>
      <Route exact path={match.path}>
        <DataShareList />
      </Route>
    </Switch>
  )
}

export default DataShare;