import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Row } from 'components/Layout'
import Button from 'components/Button'
import DeadLine from './components/DeadLine';
import Status from './components/Status';
import { Loading } from 'components/Icons'

import { Api } from './service/api';

import './DataShare.scss'
import useMediaQuery from 'services/useMediaQuery'

const api = new Api();

function DataShare() {
  const { t } = useTranslation();
  const history = useHistory();

  const [consents, setConsents] = useState([])
  const [Error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const mobile = useMediaQuery('(max-width: 1000px)')

  const load = useCallback(async () => {
    setLoading(true);

    const data = await api.get('open-banking/consent/list')
    if (data.errorCode) {
      setError(data)
      return
    }

    setConsents(data)
    setLoading(false);
  }, [])

  useEffect(load, [load])

  return (
    <section>
      <header className="pvt-content-header custom" style={{ marginBottom: '1rem' }}>
        <Row position={['y-center', 'x-between']}>
          <h1 className="pvt-content-title custom">{t('label.datashare.title')}</h1>
        </Row>
      </header>

      {loading && <Loading />}

      {mobile ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

          {consents.map(consent => (
            <div style={{ background: '#FFF', border: '1px solid #f0f0f0', borderRadius: 5, padding: '10px 15px', paddingBottom: 50, position: 'relative' }}>

              <div>{t('label.datashare.receiver')}: {consent.originName}</div>
              <div>Status: {<Status status={consent.status} tag />}</div>
              <div>{t('label.datashare.deadline')}: {consent.expiration ? <DeadLine deadLine={consent.expiration} /> : t('label.datashare.deadline.indefinite')}</div>
              <div>{
                <Button
                  className='pvt-btn pvt-btn-primary custom size-small'
                  onClick={() => history.push(`/data-share/detail/${consent.consentId}`)}
                  label={t('button.label.view')}
                />
              }</div>
            </div>
          ))}
        </div>
      ) :
        <div className="pvt-txt-card pvt-card pvt-card-100 pvt-table list-datashare">
          <Row className="list-row-datashare">
            <Cell head width="30%" className="list-header-datashare" value={t('label.datashare.receiver')} />

            <Cell head width="30%" className="list-header-datashare" value="Status" />

            <Cell head width="30%" className="list-header-datashare" value={t('label.datashare.deadline')} />

            <Cell head width="10%" className="list-header-datashare" value={t('yourRequests.table.actions')} />
          </Row>

          {consents.map(({ consentId, originName, status, expiration }) => {
            return (
              <Row key={consentId} className="list-row-datashare">
                <Cell width="30%" className="list-cell-datashare" value={originName} />

                <Cell width="30%" className="list-cell-datashare">
                  <Status status={status} tag />
                </Cell>

                <Cell width="30%" className="list-cell-datashare" value={expiration ? <DeadLine deadLine={expiration} /> : t('label.datashare.deadline.indefinite')} />

                <Cell width="10%" className="list-cell-datashare">
                  <Button
                    className='pvt-btn pvt-btn-primary custom size-small'
                    onClick={() => history.push(`/data-share/detail/${consentId}`)}
                    label={t('button.label.view')}
                  />
                </Cell>
              </Row>
            )
          })}
        </div>
      }
      {Error && (
        <Error.Popup
          errorCode={Error.errorCode}
          isOpen
          onClose={() => { setError(); }}
          onClickBack={() => { setError(); }}
        />
      )}
    </section >
  )
};

const Cell = ({ value, children, head, width, ...rest }) => {
  return (
    <div {...rest} style={{ width }}>
      {children ? children : (
        <span>{value}</span>
      )}
    </div>
  )
}

export default DataShare;