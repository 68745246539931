import produce from 'immer'
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

import UserStore from 'services/userLocalStorage'

export const Types = {
  LOGIN: 'auth/LOGIN',
  ERROR: 'auth/ERROR',
  LOGOUT: 'auth/LOGOUT',
  FETCHING: 'auth/FETCHING',
  INVALIDCREDENTIAL: 'auth/INVALIDCREDENTIAL',
  SIGNUP: 'auth/SIGNUP',
  PASSWORDTOKEN: 'auth/PASSWORDTOKEN',
  SIGNINTOKEN: 'auth/SIGNINTOKEN',
  SIGNUPSUCCESS: 'auth/SIGNUPSUCCESS',
  SIGNUPERROR: 'auth/SIGNUPERROR'
}

export const Creators = {
  login: (payload) => ({
    type: Types.LOGIN,
    payload,
  }),
  signup: (payload) => ({
    type: Types.SIGNUP,
    payload,
  }),
  setSignupSuccess: (payload) => ({
    type: Types.SIGNUPSUCCESS,
    payload,
  }),
  setSignupError: (payload) => ({
    type: Types.SIGNUPERROR,
    payload,
  }),
  signinToken: (payload) => ({
    type: Types.SIGNINTOKEN,
    payload,
  }),
  logout: () => ({
    type: Types.LOGOUT,
  }),
  loginError: (payload) => ({
    type: Types.ERROR,
    payload,
  }),
  fetching: (payload) => ({
    type: Types.FETCHING,
    payload,
  }),
  passwordToken: (payload) => ({
    type: Types.PASSWORDTOKEN,
    payload,
  }),
}

const defaultUser = {
  uid: null,
  avatar_url: null,
  display_name: null,
  first_name: null,
  last_name: null,
  username: null,
  name: UserStore.getUserData(),
  email: UserStore.getEmailData(),
  company: null,
  authApp: UserStore.getAuthApp(),
}

const initialState = {
  isAuthenticated: UserStore.getToken() ? true : null,
  fetching: false,
  error: null,
  signupToken: null,
  passwordToken: null,
  signinToken: UserStore.getToken(),
  signUpSuccess: null,
  signUpError: null,
  user: {
    ...defaultUser,
  }
}

function authReducer(state = initialState, action) {
  const { payload, type } = action
  // produce simplifies the immutability.. <3
  return produce(state, (draft) => {
    switch (type) {
      case Types.FETCHING:
        draft.fetching = payload
        draft.error = null
        break
      case Types.LOGIN:
        draft.isAuthenticated = true
        draft.fetching = false
        draft.user = payload
        draft.signinToken = null
        break

      case Types.LOGOUT:
        draft = { ...initialState }
        draft.isAuthenticated = false
        break
      case Types.ERROR:
        draft.isAuthenticated = false
        draft.fetching = false
        draft.user = defaultUser
        draft.error = payload
        break
      case Types.SIGNUP:
        draft.signupToken = payload
        break
      case Types.PASSWORDTOKEN:
        draft.passwordToken = payload
        break
      case Types.SIGNUPSUCCESS:
        draft.signUpSuccess = payload
        break
      case Types.SIGNINTOKEN:
        draft.signinToken = payload
        break
      case Types.SIGNUPERROR:
        draft.signUpError = payload
        break
      default:
        return draft
    }

    return draft;
  })
}

export default authReducer
