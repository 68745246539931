import React, { useRef, useReducer, useState } from 'react'

import './styles.sass'

import Label from 'components/Form/Label'
import Field from 'components/Form/Field'
import Button from 'components/Form/Button'
import Textarea from 'components/Form/Textarea'
import { useTranslation } from 'react-i18next'

import SuccessSubmit from './SuccessSubmit'

import { formValidation, formReducer, initialState } from 'components/Form/helpers'

import { Row } from 'components/Layout'
import Overlay from 'components/Overlay/Overlay'
import Popup from 'reactjs-popup'

function MessageForm({ onSubmit, request, onClickBackButton }) {

  const messageRef = useRef(null)

  const { t } = useTranslation()

  const [state, dispatch] = useReducer(formReducer, initialState)

  const inputFile = useRef(null);

  const [fileData, setFileData] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  function handleUploadClick(ev) {
    ev.preventDefault();
    inputFile.current.click()
  }

  function changeFieldFile(ev) {
    //const { name, value, id } = ev.currentTarget;
    var myFile = ev.target.files[0];

    const fileName = myFile.name.toLowerCase();
    const validFormat = fileName.endsWith(".pdf")
      || fileName.endsWith(".png")
      || fileName.endsWith(".jpg")
      || fileName.endsWith(".jpeg");

    const validSize = myFile.size < 2000000; //2MB

    var errorMessage = "";
    if (!validFormat) {
      errorMessage = t('upload.file.error.invalidFormat');
    }
    if (!validSize) {
      errorMessage = errorMessage + " " + t('upload.file.error.fileTooLarge');
      setResultMessage(t('upload.file.error.fileTooLarge.message'));
      setShowMessage(true);
    }
    setFileError(errorMessage);

    const hasError = !(validSize && validFormat);
    if (hasError) {
      setFileName("");
      setFileData("");
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setFileName(myFile.name);
      setFileData(reader.result);
    };

    reader.readAsDataURL(myFile);
  }

  const handleClosePopup = () => {
    setResultMessage('');
    setShowMessage(false);
  }

  function handleSubmit() {
    const message = messageRef.current.value.trim()

    const errors = formValidation({
      message
    })

    if (Object.keys(errors).length) {
      dispatch({ type: 'onError', payload: errors })
      return
    }

    dispatch({ type: 'onSubmiting', payload: true })

    onSubmit({ message, fileData }, () => dispatch({ type: 'onSuccess' }))
  }


  if (state.success) {
    return <SuccessSubmit />
  }

  return (
    <section className="pvt-your-request custom">
      <header className="pvt-content-header custom">
        <Row position={['y-center', 'x-start']}>
          <button
            type="button"
            className="pvt-btn2 custom"
            onClick={onClickBackButton}
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>&nbsp;
            <span>{t('button.label.back')}</span>
          </button>
          <h1 className="pvt-content-title custom">{t('yourRequests.title.new.message')}</h1>
        </Row>
      </header>
      <div className="pvt-yourrequest-subtitle">
        <p>{t('yourRequests.label.sendMessageFor')} <b>{request.protocol}</b></p>
        <p>{t('yourRequests.label.sendMessageFor.type')} <b>{request.requestType}</b></p>
      </div>
      <div className="dsar-form">
        <Field>
          <Label label={t('yourRequests.label.message')} relation="message-field" mandatory={true} />
          <Textarea
            ref={messageRef}
            relation="message-field"
            invalidMessage={state.errors?.message}
            required
            pattern="\S+"
          />
        </Field>

          <Field>
            {fileName && fileName.trim().length > 0 ?
              <div className="dsar-file-space">
                <div className="dsar-filename">{fileName}</div>
              </div>
              : null
            }
            <div className="dsar-file-space">
              {fileError && <span className="dsarFileError">{fileError || ''}</span>}
              <div className="dsar-file-label">
                <button className="dsar-button-upload" onClick={handleUploadClick}>
                  <i class="fas fa-paperclip" aria-hidden="true" />{t('yourRequests.label.addFile')}</button>
                <span className="dsarFileTip">(JPG, jPEG, PNG e PDF.)</span>
                <input style={{ display: 'none' }} type="file"
                  id={"filea"} ref={inputFile}
                  accept=".pdf,.png,.jpg,.jpeg,image/jpeg,image/png,application/pdf"
                  onChange={changeFieldFile} />
              </div>
            </div>
          </Field>

        <Field>
          <Row position={['x-end']}>
            <Button
              label={t('button.label.send')}
              onClick={handleSubmit}
              loading={state.submiting}
              disabled={true}
            />
          </Row>
        </Field>

        <Overlay visible={state.submiting} />

        <Popup open={showMessage} onClose={handleClosePopup} closeOnDocumentClick={true} position="right center" modal>
          {close =>
            <div className="popup">
              <div className="close">
                <a onClick={close}>&times;</a>
              </div>
              <div>
                {resultMessage}
              </div>
            </div>
          }
        </Popup>

      </div>
    </section>
  )
}

export default MessageForm
