export const initialState = {
  errors: {},
  submiting: false,
  success: false,
}

function reducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'onSubmiting':
      return { ...state, submiting: payload }
    case 'onError':
      return { ...state, errors: payload }
    case 'onSuccess':
      return { ...initialState, success: true }
    case 'onClear':
      return { ...initialState }
    default:
      throw new Error()
  }
}

export default reducer
