import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Auth from 'components/Auth/Auth';
import { clearSignUpErrors } from 'store/reducers/auth.thunks';

const SignupAccountAlreadyExist = () => {

    const { t } = useTranslation();
    const reduxDispatch = useDispatch();

    reduxDispatch(clearSignUpErrors());

    return (
        <section>
            <header className="pvt-content-header custom">
                <h1 className="pvt-content-title custom">{t('accountAlreadyExist.title')}</h1>
            </header>
            <Auth>
                <div>
                    <h2>{t('accountAlreadyExist.error.description')}!</h2>
                </div>

                <div className="mt-30">
                    {t('accountAlreadyExist.options.label')}:
                </div>

                <div className="mt-10">
                    <ul style={{listStyle: 'inside'}}>
                        <li>
                            <Link to="/signin">
                                {t('label.signin')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/recovery-password">
                                {t('label.reset-password')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/signup">
                                {t('label.signup-with-other-email')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </Auth>
        </section>
    );

};

export default SignupAccountAlreadyExist;
