import * as React from 'react'

import Warning from '../Icons/Warning'

import './textarea.sass'

const Textarea = React.forwardRef(({ type = 'text', className, invalidMessage, ...props }, ref) => {
  const classie = `dsar-field dsar-textarea ${className || ''}  ${invalidMessage ? 'error' : ''}`
  return (
    <>
      <div className="textarea-wrapper">
        <textarea ref={ref} className={classie} {...props}></textarea>
        {invalidMessage && <Warning height="15px" width="15px" />}
      </div>
      {invalidMessage && <div className="error-message custom">{invalidMessage || ''}</div>}
    </>
  )
})

export default Textarea
