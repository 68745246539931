import React from 'react';
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next';

import Button from 'components/Button'

import './Popup.scss';

function PopupReject ({ opened, onClose, onConfirm }) {
  const { t } = useTranslation();
  return (
    <Popup
      open={opened}
      onClose={onClose}
    >
      <div className="popup popup-consent">
        <h1>{t('label.datashare.popup.reject.h1')}</h1>

        <p>
          <span>{t('label.datashare.popup.reject.span1')} </span>
          <strong>{t('label.datashare.popup.reject.strong1')}</strong>
          <span> {t('label.datashare.popup.reject.span2')} </span>
          <strong>{t('label.datashare.popup.reject.strong2')}</strong>
          <span> {t('label.datashare.popup.reject.span3')}</span>
        </p>

        <span>{t('label.datashare.popup.reject.span4')}</span>

        <div className="popup-row popup-row-buttons">
          <Button
            type="default"
            label={t('button.label.back')}
            onClick={onClose}
          />

          <Button
            type="Primary"
            label={t('button.label.yesCancel')}
            onClick={onConfirm}
          />
        </div>

      </div>
  </Popup>
  )
}

export default PopupReject;