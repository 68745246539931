import React, { useRef, useReducer } from 'react'
import Popup from "reactjs-popup";

// store
import { useSelector } from 'react-redux'

import './styles.sass'

import Label from 'components/Form/Label'
import Field from 'components/Form/Field'
import Button from 'components/Form/Button'
import Textarea from 'components/Form/Textarea'
import Select from 'components/Form/CustomSelect'
import Input from 'components/Form/Input'
import { useTranslation } from 'react-i18next'

// import SuccessSubmit from './SuccessSubmit'

import { formValidation, formReducer, initialState } from 'components/Form/helpers'

import { Row } from 'components/Layout'
import Overlay from 'components/Overlay/Overlay'
import { useState } from 'react';
import { axiosInstance } from 'services/api'
import { lsc, getLanguageObjectByValue } from 'services/availableLanguages'

function YourRequestForm({ onSubmit, categoriesOptions = [], websiteId, isPtBr = true }) {

  const orderCategoryRefVal = useRef(null);
  const userTypeRefVal = useRef(null);
  const messageRef = useRef(null);
  const requestDetailRef = useRef(null);
  const inputFile = useRef(null);
  const emptyOption = { label: '', value: '' };
  const [fileData, setFileData] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [fileError, setFileError] = React.useState('');
  const [showMessage, setShowMessage] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState("");
  const [categoriState, setCategoriState] = useState(emptyOption);
  const [fields, setFields] = useState([]);
  const [dsarConfig, setDsarConfig] = useState({});
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [checkeDataPermission, setCheckeDataPermission] = useState(false);
  const [dsarUserType, setDsarUserType] = useState([])
  const [dsarUserTypeSelected, setDsarUserTypeSelected] = useState('')
  const { t, i18n } = useTranslation()

  const [state, dispatch] = useReducer(formReducer, initialState)

  const { geral = {} } = useSelector((store) => store)

  React.useEffect(() => {
    handleOrderCategory(emptyOption);
    loadUserType()
    loadFields()
    verifyForm()
  }, [geral.language]);


  const loadUserType = () => {
    const lang = lsc(i18n.language)
    axiosInstance.get(`${window.endPoints.api.urlPublic}/user-type/${lang ? lang : 'pt'}?d=${new Date().getTime()}`)
      .then(response => {
        const _dsarUserType = (response.data || []).map(item => ({ label: item.name, value: item.encryptedId }))
        setDsarUserType(_dsarUserType)
      })
      .catch(e => console.error(e))
  }

  const validateWebsiteId = (id) => {
    return typeof id === 'string' && id.trim() !== '' && id !== null && id !== undefined;
  }
  const searchFields = (webSiteUUid) =>{
    if(!validateWebsiteId(webSiteUUid)){
      return null
    }
    const getFields = axiosInstance.get(`public/${webSiteUUid}/fields`)
    return getFields;
  }

  const searchDsarConfig= (webSiteUUid) => {
    if(!validateWebsiteId(webSiteUUid)){
      return null
    }
    const getDsarConfig = axiosInstance.get(`public/dsarFormConfig/${webSiteUUid}`)
    return getDsarConfig;
  }

  const loadFields = () => {
    const lang = lsc(i18n.language)
    const getFields = searchFields(websiteId);
    const getDsarConfig = searchDsarConfig(websiteId);
    const getUserType = axiosInstance.get(`${window.endPoints.api.urlPublic}/user-type/${lang ? lang : 'pt'}?d=${new Date().getTime()}`)
    Promise.all([getFields, getDsarConfig, getUserType])
      .then(response => {
        const _fields = response[0].data
        const _dsarConfig = response[1].data
        const _dsarUserType = (response[2].data || []).map(item => ({ label: item.name, value: item.encryptedId }))
        setFields(_fields);
        setDsarConfig({ ..._dsarConfig, termMessageTranslation: createTranslationIndex(_dsarConfig.labels) });
        setDsarUserType(_dsarUserType)
      })
      .catch(e => console.error(e));
  }

  const handleOrderCategory = (event) => {
    orderCategoryRefVal.current = event;
    setCategoriState(event);
    verifyForm();
  };
  const handleOrderDsarUserType = (event) => {
    userTypeRefVal.current = event
    setDsarUserTypeSelected(event)
    verifyForm();
  };


  const verifyExistis = (id) => {
    const element = document.querySelector(`#${id}`)
    return !!element;
  }

  const verifyForm = () => {
    const reduceCustomFields = (p, c) => !(!p || !c);
    const customFields = fields
      .filter(f => f.required === true && verifyExistis(f.encryptedId))
      .map(f => f.value)
      .map(v => v !== null && v.trim() !== '' && v !== 'false')
      .reduce(reduceCustomFields, true);

    // valida as expressões regulares dos fields
    const customFieldsValid = fields
      .filter(f => f.regex && f.value ? !new RegExp(f.regex).test(f.value) : false)


    const { value } = orderCategoryRefVal.current
    const userTypeValue = userTypeRefVal.current?.value

    const isValid = !!customFields && !!customFieldsValid.length < 1 && !!value && userTypeValue

    setDisabledSubmit(isValid);
  }

  function handleSubmit() {
    const message = messageRef?.current?.value.trim()
    const requestDetail = requestDetailRef?.current?.value.trim()
    const category = orderCategoryRefVal.current
    const userType = dsarUserTypeSelected?.value
    let errors = {}
    if (geral.portalConfig?.showMessage) {
      errors = formValidation({
        message
      })
    }

    if (Object.keys(errors).length) {
      dispatch({ type: 'onError', payload: errors })
      return
    }

    dispatch({ type: 'onSubmiting', payload: true })

    onSubmit(
      { message, requestDetail, category, fileData, fields, userType },
      (response) => {
        const protocolRequest = response.data
        setFileData("");
        setFileName("");
        if (messageRef?.current) {
          messageRef.current.value = "";
        }
        if (requestDetailRef?.current) {
          requestDetailRef.current.value = "";
        }
        setCategoriState(emptyOption);
        dispatch({ type: 'onSuccess' })
        setResultMessage(t('yourRequests.result.success', { protocol: protocolRequest || '' }));
        setShowMessage(true);
      },
      (error) => {
        var errorKey = "yourRequests.result.error.gen";
        const res = error.response
        if (res.data === "already_exists_request") {
          errorKey = "successAlreadyExists"
        } else if (res.status === 413) {
          errorKey = "yourRequests.result.error.413"
        } else if (res.status === 401) {
          errorKey = "yourRequests.result.error.401"
        }
        dispatch({ type: 'onClear' })
        setResultMessage(t(errorKey));
        setShowMessage(true);
      }
    )
  }

  function changeFieldFile(ev) {
    var myFile = ev.target.files[0];

    const fileName = myFile.name.toLowerCase();
    const validFormat = fileName.endsWith(".pdf")
      || fileName.endsWith(".png")
      || fileName.endsWith(".jpg")
      || fileName.endsWith(".jpeg");

    const validSize = myFile.size < 2000000; //2MB

    var errorMessage = "";
    if (!validFormat) {
      errorMessage = t('upload.file.error.invalidFormat');
    }
    if (!validSize) {
      errorMessage = errorMessage + " " + t('upload.file.error.fileTooLarge');
      setResultMessage(t('upload.file.error.fileTooLarge.message'));
      setShowMessage(true);
    }
    setFileError(errorMessage);

    const hasError = !(validSize && validFormat);
    if (hasError) {
      setFileName("");
      setFileData("");
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setFileName(myFile.name);
      setFileData(reader.result);
    };

    reader.readAsDataURL(myFile);
  }

  const handleFieldFileCustom = (ev) => {
    const { id } = ev.currentTarget;
    const myFile = ev.target.files[0];

    const fileName = myFile.name.toLowerCase();
    const validFormat = fileName.endsWith(".pdf")
      || fileName.endsWith(".png")
      || fileName.endsWith(".jpg")
      || fileName.endsWith(".jpeg")

    const validSize = myFile.size < 2000000; //2MB

    var errorMessage = "";
    if (!validFormat) {
      errorMessage = t('upload.file.error.invalidFormat');
    }
    if (!validSize) {
      errorMessage = errorMessage + " " + t('upload.file.error.fileTooLarge');
    }

    const newId = id.replace('_input', '')
    const elementError = document.querySelector(`#${newId}_fileErro`)
    if (elementError) {
      elementError.innerHTML = errorMessage
    }

    if (errorMessage) {
      return;
    }

    const newfields = fields

    const field = newfields.filter(field => field.encryptedId === newId)[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      field.value = fileData
      setFields(newfields);
      document.querySelector(`#${newId}_fileName`).textContent = myFile.name;
    };
    reader.readAsDataURL(myFile);
    setTimeout(() => { verifyForm(); }, 1000)
  }

  function handleUploadClick(ev) {
    ev.preventDefault();
    inputFile.current.click()
  }

  function handleUploadClickCustom(ev) {
    ev.preventDefault();
    const { id } = ev.currentTarget;
    const element = document.querySelector(`#${id}_input`)
    if (element) {
      element.click()
    }
  }



  const handleClosePopup = () => {
    setResultMessage('');
    setShowMessage(false);
  }

  const changeCheckeDataPermission = (ev) => {
    const { checked } = ev.currentTarget;
    setCheckeDataPermission(checked);
    setTimeout(() => { verifyForm() }, 100)
  }

  const changeFields = (ev) => {
    const { id, value, checked } = ev.currentTarget;
    const newFields = Object.assign([], fields);
    newFields.forEach(field => {
      if (field.encryptedId == id) {
        if (field.type == "CHECKBOX") {
          field.value = checked ? 'true' : 'false';
        } else {
          field.value = value;
        }
      }
    })
    setFields(newFields);

    setTimeout(() => { verifyForm() }, 100)
  }

  const handleDependet = (field) => {
    const validateDependent = handleValidateFieldDependent(field)
    const validateConditions = handleValidateFieldCondition(field)
    return validateDependent && validateConditions
  }

  const handleValidateFieldDependent = (field) => {
    const { encryptedDependentId } = field
    if (encryptedDependentId) {
      const fieldDepend = fields.find(item => item.encryptedId === encryptedDependentId)

      if (fieldDepend) {
        if (fieldDepend.type !== "CHECKBOX") {
          return fieldDepend.value !== null && fieldDepend.value !== ''
        } else {
          return fieldDepend.value === 'true'
        }
      }
    }
    return true
  }

  const handleValidateFieldCondition = (field) => {
    const language = getLanguageObjectByValue(i18n.language)
    const languageCondition = field?.languages.length === 0 || field?.languages?.some(lang => lang.id == language?.code)
    const userTypeCondition = field?.dsarUserTypes.length === 0 || field?.dsarUserTypes?.some(dsarUserType => dsarUserType.encryptedId == dsarUserTypeSelected?.value)
    const requestTypeCondition = field?.configRights.length === 0 || field?.configRights?.some(configRight => configRight.encryptedId == categoriState?.value)
    //Além das condicionais se o campo não tem tradução para a linguagem ele não vai ser renderizado
    return languageCondition && userTypeCondition && requestTypeCondition && getLabel(field)
  }

  const handleFieldsValidationGeneric = (value, pattern) => {
    let isValid = true;
    if (value && pattern) {
      isValid = new RegExp(`${pattern}`).test(value);
    }
    const message = !isValid ? t('yourRequest.label.messagem.input.erro') : "";
    return message;
  }

  const getLabel = (field) => {
    const language = getLanguageObjectByValue(i18n.language)
    const label = field.fieldLabel.find(item => item.languageId === language.code)?.label
    return label ? label : ''
  }

  function createTranslationIndex(labels) {
    const translationIndex = {};

    labels.forEach(lb => {
      translationIndex[lb.languageId] = lb.termMessage;
    });

    return translationIndex;
  }

  return (
    <div className="pvt-card pvt-card-100">
      <div className="dsar-form">
        <Field>
          <Label label={t('yourRequests.label.category')} mandatory={true} />
          <Select
            placeholder=""
            onChange={handleOrderCategory}
            isSearchable={false}
            defaultValue=""
            options={categoriesOptions}
            value={categoriState}
          />
        </Field>
        <Field>
          <Label label={t('yourRequests.label.userType')} mandatory={true} />
          <Select
            placeholder=""
            onChange={handleOrderDsarUserType}
            isSearchable={false}
            defaultValue=""
            options={dsarUserType}
            value={dsarUserTypeSelected}
          />
        </Field>
        {
          fields.length > 0 ?
            fields.map(field =>
              field.type ?
                field.type == 'PLAIN_TEXT' ?
                  <>
                    {
                      handleDependet(field) ?
                        <Field>
                          <Label label={getLabel(field)} mandatory={field.required} />
                          <Input
                            id={field.encryptedId}
                            type="text"
                            value={field.value}
                            onChange={changeFields}
                            invalidMessage={handleFieldsValidationGeneric(field.value, field.regex)}
                            pattern={field.regex}
                            required={field.required}
                          />
                        </Field>
                        : null
                    }
                  </>
                  :
                  field.type == 'LARGER_TEXT' ?
                    <>
                      {
                        handleDependet(field) ?
                          <Field>
                            <Label label={getLabel(field)} mandatory={field.required} />
                            <Textarea
                              id={field.encryptedId}
                              value={field.value}
                              onChange={changeFields}
                              invalidMessage={handleFieldsValidationGeneric(field.value, field.regex)}
                              pattern={field.regex}
                              required={field.required}
                              maxLength="3000">
                            </Textarea>
                          </Field>
                          : null
                      }
                    </>
                    :
                    field.type == 'NUMBER' ?
                      <>
                        {
                          handleDependet(field) ?
                            <Field>
                              <Label label={getLabel(field)} mandatory={field.required} />
                              <Input
                                id={field.encryptedId}
                                type="number"
                                value={field.value}
                                onChange={changeFields}
                                invalidMessage={handleFieldsValidationGeneric(field.value, field.regex)}
                                pattern={field.regex}
                                required={field.required}
                              >
                              </Input>
                            </Field>
                            : null
                        }
                      </>
                      :
                      field.type == 'CHECKBOX' ?
                        <>
                          {
                            handleDependet(field) ?
                              <Field>
                                <Label label={getLabel(field)} mandatory={field.required} />
                                <input
                                  id={field.encryptedId}
                                  type="checkbox"
                                  checked={field.value === 'true' ? true : false}
                                  onChange={changeFields}
                                  pattern={field.regex}
                                  required={field.required}
                                  style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                                ></input>
                              </Field>
                              : null
                          }
                        </>
                        :
                        field.type == 'ARCHIVE' ?
                          <>
                            {
                              handleDependet(field) ?
                                <Field>
                                  <Label label={getLabel(field)} mandatory={field.required} />
                                  <div className="dsar-file-space">
                                    <div id={`${field.encryptedId}_fileName`} className="dsar-filename"></div>
                                  </div>
                                  <div className="dsar-file-space">
                                    <div id={`${field.encryptedId}_fileErro`} className="dsarFileError"></div>
                                    <div className="dsar-file-label">
                                      <button id={field.encryptedId} className="dsar-button-upload" onClick={handleUploadClickCustom}>
                                        <i className="fas fa-paperclip" aria-hidden="true" />{t('yourRequests.label.addFile')}
                                      </button>
                                      <span className="dsarFileTip">(JPG, jPEG, PNG e PDF.)</span>
                                      <input style={{ display: 'none' }} type="file"
                                        id={`${field.encryptedId}_input`}
                                        accept=".pdf,.png,.jpg,.jpeg,image/jpeg,image/png,application/pdf"
                                        onChange={handleFieldFileCustom} />
                                    </div>
                                  </div>
                                </Field>
                                : null
                            }
                          </>
                          :
                          field.type == 'SELECTOR' ?
                            <>
                              {
                                handleDependet(field) ?
                                  <Field>
                                    <Label label={getLabel(field)} mandatory={field.required} />
                                    <select
                                      className="dsar-selector"
                                      id={field.encryptedId}
                                      onChange={changeFields}
                                      isSearchable={false}
                                      value={field.value}
                                    >
                                      <option></option>
                                      {
                                        field.options.map((itens) => (
                                          <option key={`${field.encryptedId}_option`} value={itens}>
                                            {itens}
                                          </option>

                                        ))
                                      }
                                    </select>
                                  </Field>
                                  : null
                              }
                            </>
                            : null
                : null

            )
            : null
        }

        {geral.portalConfig?.showMessage &&
          <Field>
            <Label label={t('yourRequests.label.shortMessage')} relation="message-field" mandatory={true} />
            <Textarea
              ref={messageRef}
              relation="message-field"
              invalidMessage={state.errors?.message}
              required
              maxLength="3000"
              pattern="\S+"
            />
          </Field>
        }
        {geral.portalConfig?.showRequestDetail &&
          <Field>
            <Label label={t('yourRequests.label.details')} relation="requestDetail-field" />
            <Textarea
              ref={requestDetailRef}
              relation="requestDetail-field"
              pattern="\S+"
              maxLength="5000"
            />
          </Field>
        }
        {geral.portalConfig?.showArchive &&
          <Field>
            {fileName && fileName.trim().length > 0 ?
              <div className="dsar-file-space">
                <div className="dsar-filename">{fileName}</div>
              </div>
              : null
            }
            <div className="dsar-file-space">
              {fileError && <span className="dsarFileError">{fileError || ''}</span>}
              <div className="dsar-file-label">
                <button className="dsar-button-upload" onClick={handleUploadClick}>
                  <i className="fas fa-paperclip" aria-hidden="true" />{t('yourRequests.label.addFile')}</button>
                <span className="dsarFileTip">(JPG, jPEG, PNG e PDF.)</span>
                <input style={{ display: 'none' }} type="file"
                  id={"filea"} ref={inputFile}
                  accept=".pdf,.png,.jpg,.jpeg,image/jpeg,image/png,application/pdf"
                  onChange={changeFieldFile} />
              </div>
            </div>
          </Field>
        }

        <Field>
          <input
            id={"checkeDataPermission"}
            type="checkbox"
            checked={checkeDataPermission}
            onChange={ev => changeCheckeDataPermission(ev)}
            required={true}
            style={{ width: '20px', height: '20px', verticalAlign: 'middle' , marginRight:'10px'}}
          ></input>
          <span className="dsar-label-rules custom dsar-mandatory"
            dangerouslySetInnerHTML={{
              __html: dsarConfig?.termMessageTranslation ?
                dsarConfig?.termMessageTranslation[getLanguageObjectByValue(i18n.language)?.code] ?
                  dsarConfig?.termMessageTranslation[getLanguageObjectByValue(i18n.language)?.code] :
                  t('yourRequests.label.igreeRules') :
                t('yourRequests.label.igreeRules')
            }}>
          </span>
        </Field>

        {
          !disabledSubmit && checkeDataPermission ?
            <div style={{ width: '100%', height: '5%', position: 'absolute', zIndex: '9999' }}></div>
            : null
        }
        <Field>
          <Row position={['x-end']}>
            <Button
              className='pvt-btn custom pvt-btn-confirm full size-undefined'
              label={t('button.label.send')}
              onClick={handleSubmit}
              loading={state.submiting}
              disabled={disabledSubmit && checkeDataPermission}
            />
          </Row>
        </Field>
        <Overlay visible={state.submiting} />
        <Popup open={showMessage} onClose={handleClosePopup} closeOnDocumentClick={true} position="right center" modal>
          {close =>
            <div className="p-1">
              <div className="d-flex w-100">
                <h5 className='col p-1'>{t('button.label.notice')}</h5><button className='btn btn-sm btn-light right' onClick={close}>&times;</button>
              </div>
              <p className='p-2'>{resultMessage}</p>
            </div>
          }
        </Popup>
      </div >
    </div>
  )
}

export default YourRequestForm
