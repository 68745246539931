import * as React from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useTranslation } from 'react-i18next'

const customStyles = {
  container: (styles, helpers) => ({
    ...styles,
    ...(helpers.isFocused
      ? {
          boxShadow: 'none',
        }
      : {}),
  }),
  control: (styles, helpers) => {
    return {
      ...styles,
      height: '50px',
      ...(helpers.isFocused
        ? {
            boxShadow: '0px 10px 20px -13px rgba(32, 56, 117, 0.35)',
          }
        : {}),
    }
  },
  indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: () => ({ display: 'none' }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  valueContainer: (styles) => ({ ...styles }),
  input: (styles) => ({ ...styles }),
  option: (styles) => ({ ...styles }),
  menu: (styles) => ({ ...styles }),
}

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props} className>
    <svg
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      stroke="currentColor"
      viewBox="0 0 24 24"
      height="20"
      width="20"
    >
      <path d="M19 9l-7 7-7-7"></path>
    </svg>
  </components.DropdownIndicator>
)

export default (props) => {
  const { t } = useTranslation()
  if (!props.loadOptions) {
    return (
      <Select
        components={{ DropdownIndicator }}
        styles={customStyles}
        noOptionsMessage={() => t('select.empty') }
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary: 'var(--primary)',
          },
        })}
        {...props}
      />
    )
  }
  return (
    <AsyncSelect
      cacheOptions
      components={{ DropdownIndicator }}
      styles={customStyles}
      noOptionsMessage={() => t('select.empty') }
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary: 'var(--primary)',
        },
      })}
      {...props}
    />
  )
}
