import React from 'react';
import { useTranslation } from 'react-i18next';

export const statusList = ["AWAITING_AUTHORISATION", "AUTHORISED", "REJECTED", "FINISHED", "EXPIRED"]

const Status = ({ status, tag }) => {
    const { t } = useTranslation();

    const statusLabelMap = {
        [statusList[0]]: {
            label: t('label.datashare.status.pending'),
            classTag: 'pvt-label pvt-label-pendent',
            classText: 'pvt-color-pendent',
        },
        [statusList[1]]: {
            label: t('label.datashare.status.active'),
            classTag: 'pvt-label pvt-label-opened',
            classText: 'pvt-color-opened',
        },
        [statusList[2]]: {
            label: t('label.datashare.status.finished'),
            classTag: 'pvt-label pvt-label-denied',
            classText: 'pvt-color-denied',
        },
        [statusList[3]]: {
            label: t('label.datashare.status.finished'),
            classTag: 'pvt-label pvt-label-denied',
            classText: 'pvt-color-denied',
        },
        [statusList[4]]: {
            label: t('label.datashare.status.expired'),
            classTag: 'pvt-label pvt-label-pendent',
            classText: 'pvt-color-pendent',
        },
    }

    const label = statusLabelMap[status] ? statusLabelMap[status].label : ''
    const className = statusLabelMap[status]
        ? tag
            ? statusLabelMap[status].classTag
            : statusLabelMap[status].classText
        : ''

    return (
        <>
            <span
                className={className}
            >
                {label}
            </span>
        </>
    )
}

export default Status