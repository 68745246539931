import React from 'react'
import { useTranslation } from 'react-i18next'

import './ConfirmRedirect.scss'

function ConfirmRedirectText({ originName }) {
  const { t } = useTranslation()

  return (
    <div className="confirm-redirect-row">
      <p>{t('label.datashare.popup.redirect')} <span>{originName}</span></p>
    </div>
  )
}

export default ConfirmRedirectText
