import React from 'react'
import {Switch, Route, Redirect } from 'react-router-dom'
import routes, { nonNavRoutes } from './routes'

const ConditionalRoute = ({ component: Component, condition, ...rest }) => (
  <Route
    exact
    {...rest}
    render={(props) =>
      condition ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/signin', state: { from: props.location } }}
        />
      )
    }
  />
)

function Routes() {

  return (
    <Switch>
      {routes.map(({ component: Component, path, exact }) => (
        <ConditionalRoute
          key={path}
          path={path}
          exact={exact}
          component={Component}
          condition={true}
        />
      ))}

      {nonNavRoutes.map(({ component: Component, path, exact }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          component={Component}
          condition={true}
        />
      ))}
    </Switch>
  )
}

export default Routes
