import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import banner from '../../img/banner.png'
import photo1 from '../../img/photo1.png'
import photo2 from '../../img/photo2.png'
import protectedImg from '../../img/protected.png'

function HomeDefault() {

    const { t } = useTranslation()
    const { auth = {}, geral = {} } = useSelector(state => state);
    const { portalConfig } = geral;
    const { companyName } = portalConfig;

    return (
        <div>
            <div className="pvt-route-content custom unauthenticated">
                <div className="pvt-card pvt-card-100">
                    <div className="pvt-img-card-50" style={{ backgroundImage: `url(${protectedImg})` }}>&nbsp;</div>
                    <div className="pvt-txt-card-50">
                        <h2>{t('home.text.1')}</h2>
                        <p>{t('home.text.2')}</p>
                    </div>
                </div>
                <h2 className="pvt-h2-divisor">{t('home.text.3')}</h2>
                <ul className="pvt-list-icons">
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="fas fa-lock"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.4')}</h3>
                            <p>{t('home.text.5')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="far fa-address-card"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.6')}</h3>
                            <p>{t('home.text.7')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="far fa-eye"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.8')}</h3>
                            <p>{t('home.text.9')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="fas fa-user-shield"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.10')}</h3>
                            <p>{t('home.text.11')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="fas fa-chart-line"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.12')}</h3>
                            <p>{t('home.text.13')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="pvt-list-icon">
                            <figure><i className="far fa-thumbs-up"></i></figure>
                        </div>
                        <div>
                            <h3>{t('home.text.14')}</h3>
                            <p>{t('home.text.15')}</p>
                        </div>
                    </li>
                </ul>
                <div className="pvt-card-group">
                    <div className="pvt-card pvt-card-50">
                        <div className="pvt-img-card-100" style={{ backgroundImage: `url(${photo1})` }}>&nbsp;</div>
                        <div className="pvt-txt-card">
                            <h2>{t('home.text.16')}</h2>
                            <p>{t('home.text.17')}</p>
                        </div>
                    </div>
                    <div className="pvt-card pvt-card-50">
                        <div className="pvt-img-card-100" style={{ backgroundImage: `url(${photo2})` }}>&nbsp;</div>
                        <div className="pvt-txt-card">
                            <h2>{t('home.text.18')}</h2>
                            <p>{t('home.text.19')}</p>
                        </div>
                    </div>
                </div>
                <div className="pvt-card pvt-card-100 pvt-card-icon">
                    <div className="pvt-txt-card"> <i className="fas fa-user-cog"></i>
                        <h2>{t('home.text.20')}</h2>
                        <p>{t('home.text.21')}</p>
                    </div>
                    {
                        !auth.isAuthenticated && geral.portalConfig.authType ?
                            <div className="pvt-card-buttons">
                                <a href="/signin"
                                    className="pvt-btn2 mr-10 custom">
                                    {t('notLogged.signIn')}
                                </a>
                                <Link to="/signup" className="pvt-btn custom">
                                    {t('notLogged.signup')}
                                </Link>
                            </div>
                            : null

                    }
                </div>
                <div className="pvt-card pvt-card-100 pvt-card-bkg">
                    <div className="pvt-txt-card-50">
                        <h2>{t('home.text.22', { company: companyName })}</h2>
                        <p>{t('home.text.23')}</p>
                    </div>
                    <div className="pvt-img-card-50" style={{ backgroundImage: `url(${banner})` }}>&nbsp;</div>
                </div>
            </div>

        </div>

    )
}

export default HomeDefault;