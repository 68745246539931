import { axiosInstance } from 'services/api';
import UserStore from '../../../services/userLocalStorage'
import PopupError from '../components/PopupError'

export class Api {
    getTokenURL() {
        const token = UserStore.getToken()
        if (token) {
            const keysOfLocalStorage = Object.keys(window.localStorage)
            const keyToken = keysOfLocalStorage.filter(key => key.search("pv_token") >= 0)[0]
            if (keyToken) {
                // this.portalId = keyToken.split('_')[2];
                this.portalId = window.endPoints.api.portalID;
                this.token = token
                return
            } else {
                this.errorToken = true
            }
        } else {
             this.errorToken = true
        }
    }

    makeError (error) {
        const { response, request, message } = error
        let errorCode = 999;
        if (response) {
            errorCode = response.status ? response.status : errorCode
            console.log('Response:', response.data);
        } else if (request) {
            errorCode = request.status ? request.status : errorCode
            console.log('Response', request.response);
        } else {
            if (message) {
                const splitError = message.split(' ')
                errorCode = splitError[splitError.length - 1]
                console.log('message: ', message);
            }
        }
        console.log('errorCode:', errorCode);
        return {
            errorCode,
            Popup: PopupError
        }
    }

    getTokenError() {
        return this.makeError({ response: { status: 401, data: 'No find token' } })
    }

    get = async (url) => {
        this.getTokenURL()
        if (this.errorToken) {
            return this.getTokenError()
        }

        try {
            // return this.getTokenError()
            const { data } = await axiosInstance({
                method: 'GET',
                url: `${url}?portalId=${this.portalId}`,
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            })
            return data
        } catch (error) {
            return this.makeError(error)
        }
    }

    post = async (url, dataSend) => {
        this.getTokenURL()
        if (this.errorToken) {
            return this.getTokenError()
        }
        try {
            // return this.getTokenError()
            const { data } = await axiosInstance({
                method: 'POST',
                url: `${url}?portalId=${this.portalId}`,
                data: dataSend,
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            })
            return data
        } catch (error) {
            return this.makeError(error)
        }
    }
}