import React from 'react'

import { Loading } from 'components/Icons'

import './style.sass'

function Button({ label, onClick, loading = false, loadingLabel, disabled, ...props }) {
  if (loading) {
    return (
      <span className="dsar-button-loading">
        <Loading />
        {loadingLabel ?
          loadingLabel
          : null}
      </span>);
  }

  return (
    <button className="dsar-button custom" onClick={onClick} disabled={!disabled} {...props} >
      {label}
    </button>
  )
}

export default Button
