import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'


import './styles.sass'

const DropdownMenu = ({ label, selectedValue, items, handleChange }) => {

    const { t } = useTranslation()
    const [showOptions, setShowOptions] = useState(false);

    const openOptions = () => {
        setShowOptions(true);
    }

    const hideOptions = () => {
        setShowOptions(false);
    }

    const className = (item) => {
        return selectedValue.value === item.value ? 'language-option selected' : 'language-option';
    };

    const _handleChange = (item) => {
        handleChange(item);
        hideOptions();
        window.location.reload(false);
    };

    return (<div onMouseEnter={openOptions} onMouseLeave={hideOptions}>
        <div className="language-container">
            <a href="#" className="langWidth">
                <span>{label}</span>
                <img src={selectedValue.logo} alt={ t(selectedValue.langKey) } />
            </a>
            <div className={showOptions ? "language-options opened" : "language-options"}>
                {
                    items.map((item) =>
                        <a className={className(item)} href="#" key={item.value} onClick={() => _handleChange(item)}>
                            <span>{ t(item.langKey) }</span>
                            <img src={item.logo} alt={ t(item.langKey) } />
                        </a>
                    )
                }
            </div>
        </div>
    </div>);
};

DropdownMenu.propTypes = {
    label: PropTypes.string.isRequired,
    selectedValue: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default DropdownMenu;