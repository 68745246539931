import * as React from 'react'
import { useTranslation } from 'react-i18next'


import Auth from 'components/Auth'

import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'

// reducer
import reducer, { Types, initialState, validationHelper, } from './reducer'
import { useDispatch, useSelector } from 'react-redux'

import { setPassword } from 'store/reducers/auth.thunks'
import { Creators as authCreators } from 'store/reducers/auth.reducer'
//ReactPasswordStrength
import ReactPasswordStrength from 'react-password-strength';

// router
import { Redirect } from 'react-router-dom'

import './styles.sass'


function SetPassword() {
	const { t } = useTranslation()
  const [pwErrorMessage, setPwErrorMessage] = React.useState("");
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const { auth } = useSelector(state => state)
  const MINLENGTH = 10
  const MINSCORE = 4

  React.useLayoutEffect(() => {
    if (auth.signupToken) {
      reduxDispatch(authCreators.signup(null))
    }
  }, [reduxDispatch, auth.signupToken])

  function submitForm() {
    const force = document.querySelectorAll(`.ReactPasswordStrength`);

    let hasErrors = false

    if (!validationHelper.password.mandatoryValidation(state.password)) {
      force[0].classList.add('error')
      setPwErrorMessage( t('setpassword.validation.error.password') );
      hasErrors = true
    } else if (!validationHelper.password.validation(state.password)) {
      force[0].classList.add('error')
      setPwErrorMessage( t('setpassword.validation.error.passwordInvalid') );
      hasErrors = true
    } else {
      setPwErrorMessage("");
    }

    if (!validationHelper.confirmPassword.validation(state.password, state.confirmPassword)) {
      force[1].classList.add('error')
      dispatch({
        type: Types.ERROR,
        payload: { key: 'confirmPassword' },
      })
      hasErrors = true
    }

    if (!hasErrors) {
      reduxDispatch(setPassword(state.password))
    }

  }

  function dispatcherHelper(key, value) {
    dispatch({
      type: Types.DATA,
      payload: { [key]: value, key },
    })
  }

  if (!auth.passwordToken) {
    return <Redirect to="/signin" />
  }

  const changeFieldPass = (score, pass, isValid) => {
    dispatcherHelper('password', score.password)
    let cod = validationHelper.password.validation(score.password)
    handleForce(cod, score.score, 0);
    setPwErrorMessage("");
  }

  const changeFieldPassConfirm = (score, pass, isValid) => {
    dispatcherHelper('confirmPassword', score.password)
    let cod = validationHelper.password.validation(state.password, score.password)
    handleForce(cod, score.score, 1);
  }

  const handleForce = (cod, score, i) => {
    const force = document.querySelectorAll(`.ReactPasswordStrength`);
    const strength = document.querySelectorAll(`.ReactPasswordStrength-strength-desc`);

    if (score !== 0) {
      if (!cod) {
        force[i].classList.remove(`is-strength-${score}`)
        force[i].classList.add('is-strength-1')
        strength[i].innerText = "Senha Fraca"
      } else {
        force[i].classList.remove('is-strength-1');
        force[i].classList.add(`is-strength-${score}`)
        strength[i].innerText = "Muito Forte"
      }
    }
  }

  return (
    <section>
      <header className="pvt-content-header custom">
        <h1 className="pvt-content-title custom">{ t('setpassword.title') }</h1>
      </header>
      <Auth>
        <Field>
          <ul className="creteria-pass custom pvt-alert-disclaimer">
            <li><em>{ t('setpassword.password.rule1') }</em></li>
            <li><em>{ t('setpassword.password.rule2') }</em></li>
            <li><em>{ t('setpassword.password.rule3') }</em></li>
            <li><em>{ t('setpassword.password.rule4') }</em></li>
            <li><em>{ t('setpassword.password.rule5') }</em></li>
          </ul>
      <Label label={ t('setpassword.label.password') } relation="password-field" />
      <ReactPasswordStrength
        as={Input}
        type="password"
        className="dsar-field dsar-input custom"
        style={{ display: 'form-group'}}
        minLength={MINLENGTH}
        minScore={MINSCORE}
        scoreWords={[t('setpassword.PasswordStrength.scoreWords1'), 
                t('setpassword.PasswordStrength.scoreWords2'), 
                t('setpassword.PasswordStrength.scoreWords3'), 
                t('setpassword.PasswordStrength.scoreWords4'), 
                t('setpassword.PasswordStrength.scoreWords5')]}
        tooShortWord={ t('setpassword.PasswordStrength.tooShortWord') }
        changeCallback={(score, pass, isValid) => changeFieldPass(score, pass, isValid)}
        inputProps={{
          name: "password",
          value: "password",
          autoComplete: "off",
          className: "form-control custom-ReactPasswordStrength-input custom",
          placeholder: t('setpassword.placeholder.password')
        }} />
      <span className="error-message custom">{pwErrorMessage ? pwErrorMessage : null}</span>
        </Field>

    <Field>
      <Label label={'Confirme a senha'} relation="passwordConfirmation-field" />
      <ReactPasswordStrength
        className="dsar-field dsar-input custom"
        style={{ display: 'form-group' }}
        minLength={MINLENGTH}
        minScore={MINSCORE}
        scoreWords={[t('setpassword.PasswordStrength.scoreWords1'), 
                t('setpassword.PasswordStrength.scoreWords2'), 
                t('setpassword.PasswordStrength.scoreWords3'), 
                t('setpassword.PasswordStrength.scoreWords4'), 
                t('setpassword.PasswordStrength.scoreWords5')]}
        tooShortWord={ t('setpassword.PasswordStrength.tooShortWord') }
        changeCallback={(score, pass, isValid) => changeFieldPassConfirm(score, pass, isValid)}
        inputProps={{
          name: "confirmPassword",
          value: "confirmPassword",
          autoComplete: "off",
          className: "form-control custom-ReactPasswordStrength-input custom",
          placeholder: t('setpassword.placeholder.confirmPassword')
        }} />
      <span className="error-message custom">{state.errors.confirmPassword.error ? t('setpassword.validation.error.confirmPassword') : null}</span>
    </Field>

    <Button
      type="confirm"
      label={ t('setpassword.button.label.send') }
      classie="full"
      onClick={submitForm}
    />
      </Auth >
    </section >
  )
}

export default React.memo(SetPassword)
