import { createStore, applyMiddleware, compose } from 'redux'
import thunks from 'redux-thunk'
import reducers from './reducers'

const devMode = process.env.NODE_ENV !== 'production'

const composeEnhancers = devMode
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

export default createStore(reducers, composeEnhancers(applyMiddleware(thunks)))
