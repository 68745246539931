import React from 'react';

function ArrowDown () {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="1em" 
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
        <path 
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.646 9.646a.5.5 0 01.708 0L8 12.293l2.646-2.647a.5.5 0 01.708.708l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 010-.708z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.5a.5.5 0 01.5.5v9a.5.5 0 01-1 0V3a.5.5 0 01.5-.5z"
        />
    </svg>
  )
}

export default ArrowDown;