import { axiosInstance } from 'services/api'
import UserStore from 'services/userLocalStorage'

import { Creators as personalDataReducer } from './personal-data.reducer'

const endPoints = window.endPoints

export function getPersonalData(token) {
  return (dispatch) => {
    // console.log(`${endPoints.api.url}/${endPoints.api.getPersonalData}?d=${new Date().getTime()}`)
    const options = {
      method: 'GET',
      url: `${endPoints.api.url}/${endPoints.api.getPersonalData}?d=${new Date().getTime()}`,
      headers: {
        Authorization: `Bearer ${token ? token : UserStore.getToken()}`
      },
    }

    dispatch(personalDataReducer.fetching(true))
    dispatch(personalDataReducer.personalData(null))

    axiosInstance(options)
      .then(({ data }) => {
        if (!data.country) {
          data.country = "";
        }
        dispatch(personalDataReducer.personalData(data))
        dispatch(personalDataReducer.fetching(false))
      })
      .catch(() => {
        dispatch(personalDataReducer.personalData(null))
        dispatch(personalDataReducer.fetching(false))
      })
  }
}

export function updatePersonalData(params, callback) {
  return (dispatch) => {

    const data = new FormData()
    data.append('encryptedId', params.encryptedId)
    data.append('name', params.name)
    data.append('document', params.document)
    data.append('phoneNumber', params.phoneNumber)
    data.append('country', params.country);

    const options = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.updatePersonalData}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
      data,
    }

    dispatch(personalDataReducer.updating(true))

    axiosInstance(options)
      .then((data) => {
        callback(data)
        dispatch(personalDataReducer.updating(false))
      })
      .catch((error) => dispatch(personalDataReducer.personalDataError(error.message)))
  }
}

export function sendExclusionPincode(lang, callback) {
  return (dispatch) => {

    const options = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.exclusionPincode}?lang=${lang}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
      data: null,
    }

    dispatch(personalDataReducer.personalDataError(null))

    axiosInstance(options)
      .then((data) => {
        dispatch(personalDataReducer.exclusionPincodeSuccess(true))
        callback()
      })
      .catch((error) => dispatch(personalDataReducer.personalDataError(error.message)))
  }
}

export function deletePersonalData(pincode, callback) {
  return (dispatch) => {

    const data = new FormData()
    data.append('pincode', pincode)

    const options = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.deleteAllData}`,
      headers: {
        Authorization: `Bearer ${UserStore.getToken()}`
      },
      data,
    }

    dispatch(personalDataReducer.personalDataError(null))

    axiosInstance(options)
      .then((data) => {
        dispatch(personalDataReducer.exclusionSuccess(true))
        callback()
      })
      .catch((error) => dispatch(personalDataReducer.personalDataError(error.message)))
  }
}
