
function formValidation({ email, emailConfimation, tel, name, message }) {
  let errors = {}

  // message validation
  if (!message) {
    //aqui é msg de contingência, não dá pra usar i18n. O negócio é obrigar o campo message
    errors = { ...errors, message: "Por favor, não esqueça de escrever a sua mensagem" }
  }

  return errors
}

export default formValidation
