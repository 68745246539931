import React from 'react';
import { useDispatch } from 'react-redux';

import { TYPES } from '../../store/reducers/menu.reducer';

import './styles.sass';

const ResponsiveMenu = () => {

    const dispatch = useDispatch()

    const handleClick = (event) => {
        event.preventDefault();
        dispatch({ type: TYPES.TOGGLE });
    };

    // src="data:image/webp;base64,UklGRtYDAABXRUJQVlA4TMkDAAAv/8F/EJDYNpIkSfTf9cp9tLt3EQHJtm3Tzrnvx7bNutkJ21bpq2YjThdso2zbtp28/+McOZAkKZLkeUyvD+zahwjyb/6b/+a/+W/+m//mv/lv/pv/5r8JiYMEAs0WnHj0Db/980fnyrIeconcPhihamzAHLOnRhTyUbDAsQ2jzD4Oq4+ADbFnGGbeyVlfhyjwAOPMg1LUafxMwUAzye8wyd5goqFlnP0x1TDTVKNOoq8ZhpouBhdfDcNUM3zvBdEMY00n6hrVMNdUXfuHYLBJv3W8wVsx3vle+iFTMNlMIHcogNGm+M6pJg/VuGd1BTHMNpIr1xl47sYbyxuwYbhh32CrHHs3rjGE6SbiAjXtqLzARju2LtxfAOON83sS6hH/HkE9JO9prkfnexbqsfiek3pcvOexHtr3fNfjt7/8n6Dz/4jz/H/IQv7jTjryH3cmfk98PeIOnHf2Hw8n8H7W27F54o7jdmguENKOcHBhNsuxA07AKgfzBuaeuvHKAtwYYTdE4AhW7qpxywJcmdxqFBx63BnjzRhDwJ3x8ZLsQQd4g0uTUIxkcGs0vVCde9xZjbVou/ckRPQNtmKQ/sUHHteY6mP6N593gqYTSgQcnfhI/6g3yeDueBtLdLiJNzg92e7ynGtQgIDjY4XvMc2JZiIW4AOMGaaNLFcZYbIAH2WCVFrtcTm6TRrh4GONnVg8jeYcufcV4MZytHMLOojFcQL5N//Nf/Pf/Df/zX/D0PwH5n93gPnfHeBQd4D53x1g/ncH8JcfXf7uAK7ydwewnL87gJ783QHI83cHkJi/OwA3mL87gL383QHU5O8OICp/dwDEQf7uADj5uwOw8p6/OwB5/u4ArD3k7w6gNH93AJTJ/N0B+KPzdweQ0b87gOr83QFQuvJ3B2BgOH93AAY683cHQKnq3x1Auvf83QH4mcjfHQBR7L5/dwASb/m7A7DEtte/O4AIlbZg/u4AnMWT6LTogtbv72F3gPlv/pv/5r/5b/6b/2YnHpIo9Fh2ReeveXcARIw6B+W7A7DGddi+OwAlOn13AJQKT+27Awg0E787gBza9t0BMDTE7w7AUG/87gAMjcbvDoCht353APX1uwPIqd8dQCBt/O4AKDP1uwOoyN8dwFP97gCU9bsDsEbX7w6AW787AOKwfncAMfm7A6jL3x3AQf3uADzydweQlL87AEX+7gB68ncHsJy/O4Cr/N0B6PJ1Bzj1/86f/90B5n93gPnfHaBPd4D53x3gB6A7wPw3/81/89/8N//Nf/Pf/Df/zX8LEgAA" 

    return (
        <button className="pvt-responsive-menu-button custom" onClick={handleClick}>
            <img
            src="/menu-black-24dp.svg"
            alt="menu" />
        </button>
    );
}

export default ResponsiveMenu;
