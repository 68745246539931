import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { axiosInstance } from 'services/api'
import Button from 'components/Button'
import {
  getPreferences
} from 'store/reducers/preference.thunks'
import { useDispatch, useSelector } from 'react-redux'
import './styles.sass'

import { Loading } from 'components/Icons'
import { MenuItem, Box, TextField, Autocomplete} from '@mui/material'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import UserStore from 'services/userLocalStorage'

function AssetTreatmentPortal({ match, ...props }) {
    const { t } = useTranslation()
    const { auth, geral = {} } = useSelector((store) => store)
    const reduxDispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState({}); 
    const [typeAssetList, setTypeAssetList] = React.useState([])
    const [assetGroupList, setAssetGroupList] = React.useState([]);
    const [responsibleValues, setResponsibleValues] = React.useState([]);
    const [responsibleName, setResponsibleName] = React.useState();
    const [desableSave, setDesableSave] = React.useState(true)
    const [statsEncId, setStatusEncId]= React.useState()
    const [assetId, setAssetId] = React.useState()
    const [loadingAsset, setLoadingAsset] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [asset, setAsset] = React.useState({
        id: null,
        collectPointIdEncryptedId: '',
        assetType: '',
        metadatas: [],
        attributes: [],
        risks: [],
        transitions: [],
        websiteEncryptedId: '',
        reason: '',
        justification: '',
        name: '',
        code: '',
        responsibleAreas: [],
        dmTypeAsset: {
            encryptedId: '',
            nameType: ''
        },
        responsibles: [],
        visibilityStatus: "PUBLIC",
        creationDateFormatted: '',
        changeDateFormatted: '',
        configsPermission: [],
        ripd: 'NÃO',
        statusId: '',
        groups: []
    });

    let token = ''
    if(auth.signinToken === null){
        token = UserStore.getToken()
     }else{
         token = auth.signinToken
     }

     let splittedPathName = window.location.pathname.replace(`/portal/`, "").split("/");
     let portalId = splittedPathName && splittedPathName.length > 0 ? splittedPathName[0] : "";

    React.useEffect(() => {
        if (auth.isAuthenticated && geral.language) {
          reduxDispatch(getPreferences(geral.language))
        }
        // eslint-disable-next-line
      }, [reduxDispatch, geral.language])
     
    React.useEffect(() => {
       
      
        _loadAll();
        changeResponsibles();
        reloadStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);

   
    const changeField = (ev) => {
        const { name, value } = ev.target;
        doChangeField(name, value);
      }
    
      const doChangeField = (name, value) => {
        const newAsset = { ...asset };
        if (name === 'company.id') {
            newAsset['company'] = { id: value }
        } else if (name === 'dmTypeAsset') {
            newAsset[name].encryptedId = value;
        } else {
            newAsset[name] = value;
        }
        if(newAsset.name && newAsset.dmTypeAsset.encryptedId && newAsset.reason){
            setDesableSave(false)
        }
        setAsset(newAsset);
        
        if (["blockEdit", "needsExplanation"].includes(name)) {
            save(null, newAsset, false)
        }
  
        return newAsset;
      }
  
      const reloadStatus = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                url: `/dm-asset-status?statusName=Aguardando Análise&portalId=${portalId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
    
            axiosInstance(requestOptions)
                .then(({ data }) => {
                    setStatusEncId(data.encryptedId)
            }).finally(() => setLoading(false));
           
        } catch (error) {
            console.error(error);
            _showError()
        }
      }
  
    const _loadAll = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                url: `/assets?type=ASSET_TREATMENT&portalId=${portalId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }

            axiosInstance(requestOptions)
                .then(({ data }) => {
                    setTypeAssetList(data)
                }).finally(() => setLoading(false));
            const requestOptionsGroup = {
                method: 'GET',
                url: `/group?portalId=${portalId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }

            axiosInstance(requestOptionsGroup)
                .then(({ data }) => {
                    setAssetGroupList(data)
                    setLoadingAsset(true)
                }).finally(() => setLoading(false));

        } catch (error) {
            console.error(error);
            _showError()
        }
    }
  
  
  
    const save = (ev = null, assetToSave = asset, isNext = false) => {       
        setLoading(true)
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        if (!assetToSave.name || assetToSave.name === '') {
            setMessage({ type: 'error', message: t('dm.asset.validation.name') });
            setOpen(true)
            return;
        }
        if (!assetToSave.dmTypeAsset?.encryptedId || assetToSave.dmTypeAsset?.encryptedId === '') {
            setMessage({ type: 'error', message: t('dm.asset.validation.type.Asset.treatment') });
            setOpen(true)
            return;
        }
        if (!assetToSave.dmTypeAsset?.encryptedId || assetToSave.dmTypeAsset?.encryptedId === "1") {
            setMessage({ type: 'error', message: t('dataMapping.error.type.empty') });
            setOpen(true)
            return;
        }
        setLoading(true);
        let _newAsset = { ...assetToSave }
        
        _newAsset.responsibles = [{
                id: "",
                accessEncryptedId: !responsibleValues?.masterUser ? responsibleValues?.encryptedId : null,
                companyEncryptedId: responsibleValues?.masterUser ? responsibleValues?.encryptedId : null
        }]
            
  
        if (_newAsset.responsibles?.length === 0 && _newAsset.visibilityStatus === "ONLY_USERS") {
            _newAsset.visibilityStatus = "PUBLIC"
        }
  
        if ( _newAsset.visibilityStatus === "ONLY_AREAS") {
            _newAsset.visibilityStatus = "PUBLIC"
        }
        _newAsset.assetStatusDTO = {
            newStatusEncId: statsEncId
        }

        const requestOptionsAsset = {
            method: 'POST',
            url: `/dm-asset?portalId=${portalId}`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: _newAsset,
        }
        axiosInstance(requestOptionsAsset).then((response) => {
            setAssetId(response.data.id)
            const newAsset = { ...asset };
            const dateObj = new Date(response.data.creationDate);
            newAsset.creationDateFormatted = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
            setAsset(newAsset)
            setMessage({ type: 'success', message: t('edit.success.save') });
            setOpen(true)
          }).catch (e => {
            const { errorCode, name } = e.response.data;
            if (name && name === 'SAME_NAME_CODE_ERROR') {
                setMessage({ type: 'error', message: t(`dm.error.activeSameNameAndCode`) });
                setOpen(true)
            } else {
                const errorMessage = (errorCode) ? t(`plans.error.${errorCode}`) : t('genericError');
                setMessage({ type: 'error', message: errorMessage });
            }
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
    }
  
    const changeResponsibles = () => {
        const requestOptionsGroup = {
            method: 'GET',
            url: `/${portalId}/portal-asset-responsible`,
            headers: {
                Authorization: `Bearer ${token}`
            },
        }

        axiosInstance(requestOptionsGroup)
            .then(({ data }) => {
                setResponsibleValues(data.access)
                setResponsibleName(data.access?.name)
                
        }).finally(() => setLoading(false));
    }

    const _showError = () => {
        setLoading(false);
        setMessage({ type: 'error', message: t('genericError') });
        setOpen(true)
    };

    return (
        <section>

            {loadingAsset ?
            <>
                <header className='pvt-content-header custom'>
                    <h1
                        style={{
                            margin: '0 0 5px',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: 'var(--txt-surface)',
                        }}
                        >
                            {t('label.self.service.asset.title')}
                    </h1>
                    <p className='pvt-content-desc custom'>{t('label.self.service.asset.description')}</p>
                </header>
                <div className="pvt-card pvt-card-100">
                    <div className="dsar-form">
                        <div className="pvt-magical-space">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            width={'150ch'}
                            autoComplete="off"
                        >
                        {loading ?
                            <Loading />
                        :
                        null}
                        <Collapse in={open}>
                            <Alert color={message.type}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {message.message}
                            </Alert>
                        </Collapse>
                            <div>
                                <TextField label={t('dm.asset.label.assetName')} required type="text" id="naame" name="name" value={asset.name} onChange={changeField} style={{width: '52ch'  }} />   
                                <TextField label={t('label.code')} id="code" name="code" value={asset.code} onChange={changeField} />
                                <TextField select label={t('label.type')} required name="dmTypeAsset" id="dmTypeAsset"  value={asset.dmTypeAsset?.encryptedId} onChange={changeField} >
                                    {typeAssetList.filter(typeAsset => typeAsset.statusId === 1).map(item =>
                                        <MenuItem key={item.encryptedId} value={item.encryptedId}>
                                            {item.nameType}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </div>
                            <div>
                                <Autocomplete
                                    options={assetGroupList}
                                    multiple
                                    isOptionEqualToValue={(option, value) => option.encryptedId === value.encryptedId}
                                    value={asset.groups}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    onChange={(_, groups) => setAsset((previousValues) => ({...previousValues, groups }))}
                                    renderInput={(params) => (
                                        <TextField label={t('label.grupo')} {...params} />
                                    )}
                                    sx={{ width: '25ch', display: 'inline' }}
                                />                                
                                <TextField label={t("dm.default.creationDate")} id="creationDate" value={asset.creationDateFormatted} disabled />
                                <TextField label={t("label.import-person-list.table.status")} variant="filled" id="reason" name="reason"  value={t('label.mapping.asset.no.status.portal')}  onChange={changeField} disabled />
                                <TextField label={t("label.import-person-list.table.user")} variant="filled" id="responsible" name="responsible" InputLabelProps={{shrink: true}} value={responsibleName} disabled />
                            </div>
                            <div>
                                <TextField
                                    multiline
                                    label= {t('label.mapping.asset.reason')}
                                    onChange={changeField}
                                    name="reason"
                                    id="reason"
                                    minRows={3}
                                    required
                                    placeholder={t('label.mapping.asset.reason')}
                                    style={{ width: '106ch' }}
                                />
                            </div>
                            <div>
                                <Button type="primary" label={t('button.label.send')} onClick={(ev) => save(ev, asset, false)} disabled={desableSave}></Button>                    
                            </div>
                        </Box>
                        </div>
                    </div>
                </div>
                </>
                :
                <Loading />
             }
        </section>


           
    )
}
export default AssetTreatmentPortal