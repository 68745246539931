import * as React from 'react'

function Loading() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            margin: 'auto',
            background: 'none',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width="40px"
          height="40px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="rgba(0, 0, 0, 0.4)"
            strokeWidth="8"
            r="39"
            strokeDasharray="183.7831702350029 63.261056745000964"
            transform="rotate(201.157 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1.408450704225352s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      )
}

export default Loading;