import { axiosInstance } from "services/api";

const get = async (baseUrl, config) => {
  try {
    const response = await axiosInstance.get(baseUrl, config)
    return response.data
  } catch (error) {
    const { response } = error
    if (response && response.data) {
      console.log('error: ', response.data)
      if (response.data.message !== undefined)
        throw(response.data.message)
      throw(response.data)
    } else {
      throw(error.message)
    }
  }
}

export const getTokenByProtocolAndEmail = async (lang, { protocol, email }) => {
  if (!protocol || !email) {
    throw('please provide credentials')
  }

  const { urlPublic, getRequestByProtocol } = window.endPoints.api
  const baseUrl = `${urlPublic}/${lang}/${getRequestByProtocol}`
  
  const config = { params: { protocol, email } }

  return get(baseUrl, config)
}

export const validPinCode = async ({ token, pincode }) => {
  if (!token) {
    throw('please provide token')
  }

  const { url, getRequestByProtocol } = window.endPoints.api
  const baseUrl = `${url}/${getRequestByProtocol}/valid/pincode`

  const config = { 
    params: { pincode },
    headers: { Authorization: `Bearer ${token}` }
  }

  return get(baseUrl, config)
}