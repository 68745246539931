import React from 'react'
import './styles.sass'

function Col({children, className, style, ...rest}) {
	return <div {...rest} className={`pvt-col ${className ? className : ''}`} style={style}>{children}</div>
}

function Row({className, children, position = [], ...rest}) {
    const defaultClasses = `pvt-row ${position.join(' ')}`;
    const classes = className && className !== '' ? `${defaultClasses} ${className ? className : ''}` : defaultClasses
	return <div {...rest} className={classes}>{children}</div>
}

export {
    Col,
    Row
}
