import produce from 'immer'
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

export const Types = {
    FETCHING: 'preferences/FETCHING',
    GETPREFERENCES: 'preferences/GETPREFERENCES'
}

export const Creators = {
    fetching: (payload) => ({
        type: Types.FETCHING,
        payload,
    }),
    getPreferences: (payload) => ({
        type: Types.GETPREFERENCES,
        payload,
    }),

}

const initialState = {
    fetching: false,
    preferences: []
}

function preferencesReducer(state = initialState, action) {
    const { payload, type } = action
    // produce simplifies the immutability.. <3
    return produce(state, (draft) => {
        switch (type) {
            case Types.FETCHING:
                draft.fetching = payload
                draft.error = null
                break
            case Types.GETPREFERENCES:
                draft.fetching = false
                draft.preferences = payload || []
                break
            default:
                return draft
        }
    })
}

export default preferencesReducer
