import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';

const SectionData = ({ sectionData }) => {
  const { t } = useTranslation();

  const sectionDataWithLabel = useMemo(
    () => Object.keys(sectionData).map(key => ({
      key,
      label: t(`label.datashare.detail.${key}`),
      data: [
        ...sectionData[key].map(data => t(`label.datashare.detail.${key}.${data}`) )
      ]
    })),
    [t, sectionData]
  )

  return (
    <>
      {sectionDataWithLabel.map(({ key, label, data }) => {

        return (
          <div key={key} style={{ marginBottom: '20px' }}>
            <strong>{label}</strong>

            {data.map(dataLabel => (
              <div key={dataLabel} >
                <span>{dataLabel}</span>
              </div>
            ))}
          </div>
        )
      })}
    </>
  )
}

export default SectionData