import produce from 'immer'
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

export const Types = {
    FETCHING: 'requestsDetails/FETCHING',
    GETREQUESTSDETAILS: 'requestsDetails/GETREQUESTSDETAILS',
    GETREQUESTSDETAILSLABEL: 'requestsDetails/GETREQUESTSDETAILSLABEL',
    SENDING: 'requestsDetails/SENDREQUESTSDETAILS',
    ERROR: 'requestsDetails/ERROR',
}

export const Creators = {
    fetching: (payload) => ({
        type: Types.FETCHING,
        payload,
    }),
    sending: (payload) => ({
        type: Types.SENDING,
        payload,
    }),
    getRequestsDetails: (payload) => ({
        type: Types.GETREQUESTSDETAILS,
        payload,
    }),
    getRequestsDetailsLabel: (payload) => ({
        type: Types.GETREQUESTSDETAILSLABEL,
        payload,
    }),
    error: (payload) => ({
      type: Types.ERROR,
      payload,
    }),

}

const initialState = {
  fetching: false,
  requestsDetails: [],
  requestsDetailsLabel: [],
  error: null,
}

function requestsDetailsReducer(state = initialState, action) {
    const { payload, type } = action
    // produce simplifies the immutability.. <3
    return produce(state, (draft) => {
        switch (type) {
          case Types.FETCHING:
            draft.fetching = payload
            draft.error = null
            break
          case Types.GETREQUESTSDETAILS:
            //draft.fetching = false
            draft.requestsDetails = payload
            break
          case Types.SENDING:
            draft.sending = payload
            break
          case Types.GETREQUESTSDETAILSLABEL:
            draft.requestsDetailsLabel = payload
            break
          case Types.ERROR:
            draft.fetching = false
            draft.error = payload
            break
          default:
            return draft
        }
    })
}



export default requestsDetailsReducer
