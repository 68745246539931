import produce from 'immer'
import {enableES5} from "immer";
/* eslint-disable import/first */
enableES5();

export const Types = {
    FETCHING: 'personal-data/FETCHING',
    UPDATING: 'personal-data/UPDATING',
    GETPERSONALDATA: 'personal-data/PERSONALDATA',
    ERROR: 'personal-data/ERROR',
    EXCLUSIONPINCODESUCCESS: 'personal-data/EXCLUSIONPINCODESUCCESS',
    EXCLUSIONSUCCESS: 'personal-data/EXCLUSIONSUCCESS',
}

export const Creators = {
    fetching: (payload) => ({
        type: Types.FETCHING,
        payload,
    }),
    updating: (payload) => ({
        type: Types.UPDATING,
        payload,
    }),
    personalData: (payload) => ({
        type: Types.GETPERSONALDATA,
        payload,
    }),
    exclusionPincodeSuccess: (payload) => ({
      type: Types.EXCLUSIONPINCODESUCCESS,
      payload
    }),
    exclusionSuccess: (payload) => ({
      type: Types.EXCLUSIONSUCCESS,
      payload
    }),
    personalDataError: (payload) => ({
        type: Types.ERROR,
        payload,
      }),
}

const initialState = {
  fetching: false,
  updating: false,
  error: null,
  person: null,
  exclusionPincodeSuccess: null,
  exclusionSuccess: null,
}

function personalDataReducer(state = initialState, action) {
    const { payload, type } = action
    // produce simplifies the immutability.. <3
    return produce(state, (draft) => {
        switch (type) {
          case Types.FETCHING:
            draft.fetching = payload
            draft.error = null
            break
          case Types.UPDATING:
            draft.updating = payload
            draft.error = null
            break
          case Types.GETPERSONALDATA:
            //draft.fetching = false
            draft.person = payload
            break
          case Types.EXCLUSIONPINCODESUCCESS:
            draft.exclusionPincodeSuccess = payload
            draft.error = null
            break
          case Types.EXCLUSIONSUCCESS:
            draft.exclusionSuccess = payload
            draft.error = null
            break
          case Types.ERROR:
            //draft.fetching = false
            draft.updating = false
            draft.person = null
            draft.error = payload
            break
          default:
            return draft
        }
    })
}



export default personalDataReducer
