import React, { useReducer, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Alert from 'components/Alert';
import Auth from 'components/Auth'
import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import { Loading } from 'components/Icons'

import reducer, { Types, initialState, validationHelper, ErrorMessages, ErrorTypes, } from './reducer'
import { getTokenByProtocolAndEmail } from './api';

function FormProtocol({ setFormProtocolData }) {
  const captchakey = process.env.REACT_APP_RECAPTCHA_KEY;
  const { t } = useTranslation()

  const { geral: { language } = {} } = useSelector(state => state)
  const [state, dispatch] = useReducer(reducer, initialState)

  const [captchaValue, setCaptchaValue] = useState('');
  const [isSending, setIsSending] = useState(false)
  const [apiRequestError, setApiRequestError] = useState()

  async function submitForm() {
    const { errors, ...data } = state;
    
    setApiRequestError();

    let hasErrors = false
    Object.entries(data).forEach(([key, val]) => {
      if (!validationHelper[key].mandatoryValidation(val)) {
        hasErrors = true
        dispatch({
          type: Types.ERROR,
          payload: { key, errorType: ErrorTypes.MANDATORY }
        })
      }
    });

    const errorObjectList = Object.entries(errors);
    hasErrors = hasErrors || errorObjectList.some(array => array[1].error);

    if (!hasErrors) {
      const newData = { ...data }
      try {
        setIsSending(true);
        const token = await getTokenByProtocolAndEmail(language, newData)
        setIsSending(false);
        setFormProtocolData({
          token,
          ...newData,
        })
      } catch (error) {
        setIsSending(false);
        const invalid = error.split(' ')[0]
        if (invalid === 'Invalid') {
          setApiRequestError(`findRequestByProtocol.error.invalid.ProtocolOrEmail`)
        } else {
          setApiRequestError('findRequestByProtocol.error.generic')
        }
      }
    }
  }

  function updateField({ target }) {
    const { name, value } = target;

    dispatch({
      type: Types.DATA,
      payload: { [name]: value, key: name },
    })

    const validationFunc = validationHelper[name].validation;

    if (validationFunc) {
      if (!validationFunc(value)) {
        dispatch({
          type: Types.ERROR,
          payload: { key: name, errorType: ErrorTypes.INVALID },
        })
      } else {
        dispatch({
          type: Types.VALID,
          payload: { key: name },
        })
      }
    }
  }

  const onChangeCaptcha = _captcha => {
    setCaptchaValue(_captcha);
  }

  return (
    <section>
      <Auth>
        <header className="pvt-content-header custom">
          <h2 className="pvt-content-title custom">{t('findRequestByProtocol.title')}</h2>
        </header>

        {apiRequestError && (
          <Field>
            <Alert
              align="center"
              size="full"
              type="error"
              text={<b>{t(apiRequestError)}</b>}
            />
          </Field>
        )}

        <Field>
          <Label label={t('findRequestByProtocol.label.protocol')} relation="protocol-field" mandatory={true} />
          <Input
            id="protocol-field"
            type="text"
            name="protocol"
            onChange={updateField}
            invalidMessage={state.errors.protocol.error ? t(ErrorMessages.protocol[state.errors.protocol.type]) : null}
            defaultValue={initialState.protocol}
            maxLength={50}
          />
        </Field>

        <Field>
          <Label label={t('findRequestByProtocol.label.email')} relation="email-field" mandatory={true} />
          <Input
            id="email-field"
            type="email"
            name="email"
            onChange={updateField}
            defaultValue={initialState.email}
            invalidMessage={state.errors.email.error ? t(ErrorMessages.email[state.errors.email.type]) : null}
          />
        </Field>

        <Field className="singup-recaptcha">
          <ReCAPTCHA sitekey={captchakey} onChange={onChangeCaptcha} />
        </Field>

        {isSending ? (
          <Loading />
        ) : (
          <Button
            type="confirm"
            label={t('findRequestByProtocol.button.label.send')}
            classie="full"
            disabled={!captchaValue}
            onClick={submitForm}
          />
        )}
      </Auth>
    </section>
  )
}

export default React.memo(FormProtocol)