import { isEmail } from 'validator'

const mandatoryValidation = (value) => value && !!value.trim().length

export const Types = {
    DATA: 'create-account/data',
    ERROR: 'create-account/ERROR',
    VALID: 'create-account/VALID'
}

export const ErrorTypes = {
    MANDATORY: 'mandatory',
    INVALID: 'invalid',
}

export const validationHelper = {
    protocol: {
        mandatory: true,
        mandatoryValidation,
    },
    email: {
        mandatory: true,
        mandatoryValidation,
        validation: (email) => !!isEmail(email),
    },
}

export const ErrorMessages = {
    protocol: {
        mandatory: 'findRequestByProtocol.validation.error.protocol'
    },
    email: {
        mandatory: 'findRequestByProtocol.validation.error.email',
        invalid: 'findRequestByProtocol.validation.error.emailInvalid'
    },
}

export const initialState = {
    protocol: '',
    email: '',
    errors: {
        protocol: {
            error: false,
            type: '',
        },
        email: {
            error: false,
            type: '',
        },
    },
}

function reducer(state, { type, payload }) {
    const { key, errorType, ...rest } = payload

    if (type === Types.DATA) return {
        ...state,
        ...rest,
        errors: {
            ...state.errors,
            [key]: {
                ...state.errors[key],
                error: false
            },
        },
    }
    if (type === Types.ERROR) return {
        ...state,
        errors: {
            ...state.errors,
            [key]: {
                ...state.errors[key],
                error: true,
                type: errorType,
            },
        },
    }
    if (type === Types.VALID) return {
        ...state,
        errors: {
            ...state.errors,
            [key]: {
                ...state.errors[key],
                error: false,
                type: '',
            },
        },
    }
    return {
        ...state
    }
}

export default reducer