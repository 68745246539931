import React, { useEffect, useState } from 'react';
import Label from 'components/Label/Label';

const Types = {
    Replied: 'concluded',
    New: 'pendent',
    Opened: 'opened',
    Closed: 'concluded',
    Denied: 'denied'
};

const StatusTypes = {
    'REPLIED': Types.Replied,
    'REPLIED_BY_HOLDER': Types.Replied,
    'NEW': Types.New,
    'OPEN': Types.Opened,
    'AUTOMATION': Types.Opened,
    'ANONIMYZED': Types.Opened,
    'CLOSED': Types.Closed,
    'REJECT': Types.Denied,
};

const StatusLabel = ({ status, statusCode }) => {

    const [type, setType] = useState('');

    useEffect(() => {
        const _status = StatusTypes[statusCode ? statusCode : status] || '';
        setType(_status);
    });

    return type ?
        <Label text={status} type={type} />
        : <span />;
};

export default StatusLabel;
