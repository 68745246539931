import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DeadLine = ({ deadLine }) => {
    const { t } = useTranslation();
    
    const deadLineFormat = useMemo(() => {
        const dtNow = new Date()
        dtNow.setHours(0)
        dtNow.setMinutes(0)
        dtNow.setSeconds(0)
        dtNow.setMilliseconds(0)

        const dtDeadLine = new Date(deadLine)
        const diffTime = dtDeadLine.getTime() - dtNow.getTime()
        const dtExpLocale = dtDeadLine.toLocaleDateString()
        const daysInt = Number(diffTime / 1000 / 60 / 60 / 24 + 1).toFixed(0)
        const monthsInt = (daysInt > 30) ? (daysInt / 30).toFixed(0) : 0
        
        const to = t('label.datashare.to')
        const expired = t(`label.datashare.expired`)
        const monthsStr = t(`label.datashare.month${monthsInt>1 ? 's' : ''}`)
        const daysStr = t(`label.datashare.day${daysInt>1 ? 's' : ''}`)

        if (monthsInt > 0) {
            return `${to} ${dtExpLocale} ${monthsInt} (${monthsStr})`
        } else if (daysInt > 0) {
            return `${to} ${dtExpLocale} ${daysInt} (${daysStr})`
        } else {
            return `${expired} ${dtExpLocale}`
        }
    }, [t, deadLine])

    return !!deadLine ? deadLineFormat : ""
}

export default memo(DeadLine)